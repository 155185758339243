import { FormikProps, useFormikContext } from "formik";
import { useContext, useEffect, useMemo, useState } from "react";
import { FormattedMessage } from 'react-intl';
import { CountryCodes, FieldType, SectionType } from "../components/types";
import { checkIfIsStateField, getPostalCodeCountryByState, isPostalCodeValid } from "../helpers";
import { CommonDataContext } from "../context";
import _ from "lodash";

export default function usePostalcode(field: FieldType, section: SectionType, value: string) {

  const formik: FormikProps<any> = useFormikContext();

  const { highlightErrorFieldsState } = useContext(CommonDataContext);

  const [isInvalid, setIsInvalid] = useState(false);
  const [postalCodeCountry, setPostalCodeCountry] = useState<CountryCodes>(CountryCodes.US);

  const checkIfInvalid = (value: string): boolean => {
    const isRequiredAndEmpty = !!field.config.required && !value;
    return isRequiredAndEmpty || !isPostalCodeValid(value, postalCodeCountry);
  };

  const HelperText = useMemo(() => (
    postalCodeCountry === CountryCodes.US
      ? <FormattedMessage
        id={'incorrectPostalCodeUSFormat'}
        defaultMessage={'incorrectPostalCodeUSFormat'}
      />
      : <FormattedMessage
        id={'incorrectPostalCodeCanadianFormat'}
        defaultMessage={'incorrectPostalCodeCanadianFormat'}
      />
  ), [postalCodeCountry]);

  const checkAndSetIsInvalid = (value: string) => {
    setIsInvalid(checkIfInvalid(value));
  };

  useEffect(() => {
    const nearestStateField = section?.fields.find((f: FieldType) => checkIfIsStateField(f.config.fieldName));
    if (!nearestStateField) {
      setPostalCodeCountry(CountryCodes.US);
      return;
    }

    let nearestStateFieldName = nearestStateField.config.fieldName;
    const isComplexKeyUsed = field.config.fieldName.includes('.');
    if (isComplexKeyUsed) {
      const prefix = field.config.fieldName.slice(0, field.config.fieldName.lastIndexOf(".") + 1);
      nearestStateFieldName = `${prefix}${nearestStateFieldName}`;
    }
    const postalCodeCountry = getPostalCodeCountryByState(_.get(formik.values, nearestStateFieldName));
    setPostalCodeCountry(postalCodeCountry);
  }, [formik.values]);

  useEffect(() => {
    if (value) {
      setIsInvalid(checkIfInvalid(value));
    }
  }, [postalCodeCountry]);

  useEffect(() => {
    if (highlightErrorFieldsState.state) {
      setIsInvalid(!!formik.getFieldMeta(field.config.fieldName).error);
    }
  }, [highlightErrorFieldsState]);

  return {
    HelperText,
    isInvalid,
    checkAndSetIsInvalid,
  };
}