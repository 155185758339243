import { Box, Grid, TextField, InputLabel } from '@mui/material';
import { FormikProps, useFormikContext } from 'formik';
import _ from 'lodash';
import { useCallback, useEffect, useContext } from 'react';
import { CommonDataContext } from '../../context';
import { FieldType } from '../types';

import usePostalcode from '../../hooks/usePostalcode';

export const PostalcodeField = ({
  itemData,
  value,
  displayLabel,
  isNeedToMoveLabelOutOfInput,
  externalData,
}: {
  itemData: FieldType;
  value: any;
  displayLabel: string;
  isNeedToMoveLabelOutOfInput: boolean;
  externalData: any;
}) => {
  const { id: fieldId, config: fieldConfig } = itemData;

  const formik: FormikProps<any> = useFormikContext();
  const { isMobileView } = useContext(CommonDataContext);
  const { isInvalid, checkAndSetIsInvalid, HelperText } = usePostalcode(itemData, externalData?.sectionConfig, value);

  const handleChange = useCallback(
    (e) => {
      const value = e.target.value.toUpperCase();
      formik.setFieldValue(itemData.config.fieldName, value, false);
      checkAndSetIsInvalid(value);
    },
    [formik]
  );

  const _resetField = (): void => {
    // Check if error is already set to ''
    if (Boolean(_.get(formik.errors, itemData.config.fieldName))) {
      //Remove Error
      formik.setFieldError(itemData.config.fieldName, '');
    }

    if (!_.has(formik.values, itemData.config.fieldName)) { // do not set '' value for untouched fields
      return;
    }

    //Erase value
    formik.setFieldValue(itemData.config.fieldName, '');
  };

  useEffect(() => {
    if (value) {
      checkAndSetIsInvalid(value);
    }
    return () => {
      formik.setFieldError(itemData.config.fieldName, '');
    };
  }, []);

  useEffect(() => {
    //When there is no need to display the field, we reset it's state to a clean one
    if (!itemData.config.displayed) {
      _resetField();
    } else {
      let isEmpty = false;
      if (itemData.config.required) {
        isEmpty = _.isEmpty(value);
      }
      formik.setFieldError(
        itemData.config.fieldName,
        isInvalid || isEmpty ? 'required' : ''
      );
    }
  }, [itemData.config.displayed, itemData.config.required, isInvalid, value]);

  return (
    <Box
      component={Grid}
      item
      xl={_.get(fieldConfig, 'columns.xl', 12)}
      lg={_.get(fieldConfig, 'columns.lg', 12)}
      md={_.get(fieldConfig, 'columns.md', 12)}
      sm={_.get(fieldConfig, 'columns.sm', 12)}
      xs={_.get(fieldConfig, 'columns.xs', 12)}
      display={{
        xl: !itemData.config.displayed ? 'none' : 'block',
        lg: !itemData.config.displayed ? 'none' : 'block',
        md: !itemData.config.displayed ? 'none' : 'block',
        sm: !itemData.config.displayed ? 'none' : 'block',
        xs: !itemData.config.displayed ? 'none' : 'block',
      }}
    >
      {isNeedToMoveLabelOutOfInput && <InputLabel className='outer-label'>{displayLabel}</InputLabel>}
      <TextField
        key={fieldId}
        name={fieldConfig.fieldName}
        fullWidth={fieldConfig.fullWidth}
        disabled={fieldConfig.disabled}
        error={isInvalid}
        helperText={isInvalid && HelperText}
        variant={isMobileView ? "outlined" : "standard"}
        label={isNeedToMoveLabelOutOfInput ? '' : displayLabel}
        required={fieldConfig.required}
        value={value ? value : ''}
        onChange={handleChange}
      />
    </Box>
  );
};
