import _ from 'lodash';
import currency from 'currency.js';
import {
  ApplicationType,
  Maybe,
} from '../api/types';
import {
  CreateCreditApplicationInput,
  ReferencesInput,
  ReferenceType,
  BusinessOwnerInput,
  EquipmentInput,
} from '@trnsact/trnsact-shared-types/dist/generated';
import { validate } from 'uuid';
import { getOwnerTheSameAsPOC, getURLParameter } from '../helpers';
import { APP_ENV } from '../config';


/**
 * Submits to API via GraphQL
 * Transforms data shape into expected by schema
 * @param values
 */
export const apiMapping = ({
  values,
  vendorContactId,
  vendorGUID,
  formId,
  locationId,
  extDmsOpportunityId,
  draft,
  activeTemplate,
  lenderProfileId,
  source,
  attemptToSubmit
}: {
  values?: any;
  vendorContactId?: string;
  vendorGUID?: string;
  formId?: string;
  locationId?: string;
  extDmsOpportunityId?: string | undefined,
  draft?: boolean;
  activeTemplate?: any;
  lenderProfileId?: string | null;
  source?: string | null | undefined;
  attemptToSubmit?: boolean;
}): CreateCreditApplicationInput => {
  /**
   * Returns Code convention
   * @returns
   */
  const _determineChannel = () => {
    if (vendorContactId) return 'VSP';
    if (vendorGUID) return 'Dealer';
    return '';
  };
  const _getStringifiedNumbers = (value: string | null) => {
    if (!value) return null;
    else return value.replace(/\D+/g, '');
  };
  /**
   *
   * @returns Date Established
   */
  const _getDateEstablished = (): string | null => {
    let dateBuilt: string = '';
    let monthEstablished = _.get(values, 'monthBusinessEstablished', '01');
    if (_.get(values, 'yearBusinessEstablished')) {
      dateBuilt = `${monthEstablished ? monthEstablished : '01'}-01-${_.get(
        values,
        'yearBusinessEstablished'
      )}`;
    }

    return dateBuilt ? dateBuilt : null;
  };

  /**
   *
   * @returns Date Incorporated
   */
  const _getDateIncorporated = (): string | null => {
    let dateBuilt: string = '';
    let monthIncorporated = _.get(values, 'monthBusinessIncorporated', '01');
    let yearBusinessIncorporated = _.get(
      values,
      'yearBusinessIncorporated',
      '1969'
    );
    if (_.get(values, 'yearBusinessIncorporated')) {
      dateBuilt = `${monthIncorporated ? monthIncorporated : '01'
        }-01-${yearBusinessIncorporated}`;
    }

    return dateBuilt ? dateBuilt : null;
  };
  /**
   * Returns Integer Number
   * @param path
   * @returns
   */
  const _getIntNumberByPath = (path: string): Maybe<number> => _getIntNumberByValue(_.get(values, path, null));

  /**
   * Returns Integer Number
   * @param value
   * @returns
   */
  const _getIntNumberByValue = (value: any = null): Maybe<number> => {
    if (value === null || value === '') {
      return null;
    } else {
      if (isNaN(value)) {
        value = value.replace(/[^0-9]/g, '');
      }
      return parseInt(value);
    }
  };

  /**
   * Transforms a word into a boolean, e.g "true" => true
   * @param path
   * @returns
   */
  const _getBoolByPath = (path: string): Maybe<boolean> => _getBoolByValue(_.get(values, path, null));

  /**
 * Transforms a word into a boolean, e.g "true" => true
 * @param value
 * @returns
 */
  const _getBoolByValue = (value: any): Maybe<boolean> => {
    if (value === null || value === '') {
      return null;
    }
    return value === true || value === 'true' || value === 'yes';
  };

  /**
   *Returns float number
   * @param path
   * @returns
   */
  const _getFloatNumberByPath = (path: string): Maybe<number> => _getFloatNumberByValue(_.get(values, path, null));

  /**
 *Returns float number
 * @param value
 * @returns
 */
  const _getFloatNumberByValue = (value: any = null): Maybe<number> => {
    if (value === null || value === '') {
      return null;
    } else {
      return currency(value, {
        precision: 2,
      }).value;
    }
  };

  /**
 *Returns an array of selected values, or null if nothing was selected
 * @param path
 * @returns
 */
  const _getMultiSelectValues = (path: string) => {
    const value = _.get(values, path, null);
    return _.isEmpty(value)
      ? null
      : value;
  };

  // OCA templates may use either applyingFor or applyingSelect fields
  const getApplyingForValue = () => {
    if (_.get(values, 'applyingSelect', null) === 'both') {
      return ['equipmentFinancing', 'creditLine'];
    }
    return _.get(values, 'applyingFor', null) ||
      (_.get(values, 'applyingSelect', null)
        ? [_.get(values, 'applyingSelect')]
        : null);
  };


  // we want to populate owner's signature/title to POC. It will be set for POC's owner in api-server then
  /*   const getSignatureOrTitleForPayload = (key: 'signature' | 'personalGuaranteeSignature' | 'title') => {
      const valueFromField = _.get(values, key);  // value could be set by field or DynamicSignatureAndTitle --> SignField component
      if (valueFromField) {
        return valueFromField;
      }
      const owners = _.get(values, 'owners', []);
      const ownerTheSameAsPOC = getOwnerTheSameAsPOC(_.get(values, 'email'), owners);
      return !!ownerTheSameAsPOC
        ? _.get(ownerTheSameAsPOC, key)
        : null;
    }; */

  const getReferences = (): Array<Maybe<ReferencesInput>> => {
    const intNumberFields = ['years', 'months', 'monthlyMiles'];
    const floatNumberFields = ['percRevenue', 'percentGross', 'originalLoanAmt', 'lineOfCredit', 'creditLimit', 'currentBalance', 'monthlyPayment', 'salary', 'monthlyRevenue', 'paidPerMile', 'averageBalance', 'physicalDamageDeduction', 'liabilityCoverage'];

    const getLegacyReferences = (): Array<Maybe<ReferencesInput>> => {
      const getTradeReferenceFields = (): Array<Maybe<ReferencesInput>> => {
        const tradeReferenceFieldsConfiguration = {
          tradeReference: {
            fieldsMapping: {
              companyName: 'tradeReferenceName',
              contactName: 'tradeReferenceContactName',
              contactEmail: 'tradeReferenceContactEmail',
              contactPhone: 'tradeReferencePhone',
              contactFax: 'tradeReferenceFax',
              accountNum: 'tradeReferenceAccountNum',
              percRevenue: 'percRevenue',
              years: 'tradeReferenceYears',
              months: 'tradeReferenceMonths',
            },
            referenceType: 'TRADE',
          },
          tradeReference2: {
            fieldsMapping: {
              companyName: 'tradeReferenceName2',
              contactName: 'tradeReferenceContactName2',
              contactEmail: 'tradeReferenceContactEmail2',
              contactPhone: 'tradeReferencePhone2',
              contactFax: 'tradeReferenceFax2',
              accountNum: 'tradeReferenceAccountNum2',
              percRevenue: 'percRevenue2',
              years: 'tradeReferenceYears2',
              months: 'tradeReferenceMonths2',
            },
            referenceType: 'TRADE_TWO',
          },
          tradeReference3: {
            fieldsMapping: {
              companyName: 'tradeReferenceName3',
              contactName: 'tradeReferenceContactName3',
              contactEmail: 'tradeReferenceContactEmail3',
              contactPhone: 'tradeReferencePhone3',
              contactFax: 'tradeReferenceFax3',
              accountNum: 'tradeReferenceAccountNum3',
              percRevenue: 'percRevenue3',
              years: 'tradeReferenceYears3',
              months: 'tradeReferenceMonths3',
            },
            referenceType: 'TRADE_THREE',
          },
        };

        const getTradeRefObject = (tradeRefSetting: any): ReferencesInput | null => {
          const tradeRefValueFields = _.values(tradeRefSetting.fieldsMapping);
          if (_.every(tradeRefValueFields, key => !values[key])) {
            return null;
          }
          const tradeRefObject: any = { referenceType: tradeRefSetting.referenceType };
          _.forEach(tradeRefSetting.fieldsMapping, (valueField, payloadKey) => {
            let payloadValue;
            switch (true) {
              case _.includes(intNumberFields, payloadKey):
                payloadValue = _getIntNumberByPath(valueField);
                break;
              case _.includes(floatNumberFields, payloadKey):
                payloadValue = _getFloatNumberByPath(valueField);
                break;
              default:
                payloadValue = _.get(values, valueField, null);
            }
            tradeRefObject[payloadKey] = payloadValue;
          });
          return tradeRefObject;
        }

        return _(tradeReferenceFieldsConfiguration)
          .map(getTradeRefObject)
          .filter()
          .value();
      };

      let references: Array<Maybe<ReferencesInput>> = [];
      if (_.get(values, 'primaryBankName', null)) {
        references.push({
          companyName: _.get(values, 'primaryBankName', null),
          contactName: _.get(values, 'primaryBankContactName', null),
          contactEmail: _.get(values, 'primaryBankContactEmail', null),
          contactPhone: _.get(values, 'primaryBankPhone', null),
          accountNum: _.get(values, 'primaryBankAccountNum', null),
          currentBalance: _getFloatNumberByPath(`primaryBankAverageBalance`),
          referenceType: ReferenceType.Bank,
        });
      }

      if (_.get(values, 'loanReferenceBankName', null)) {
        references.push({
          companyName: _.get(values, 'loanReferenceName', null),
          contactName: _.get(values, 'loanReferenceContactName', null),
          monthlyPayment: _.get(values, 'loanReferenceMonthlyPayment', null),
          contactEmail: _.get(values, 'loanReferenceContactEmail', null),
          referenceType: ReferenceType.Loan,
        });
      }

      if (_.get(values, 'primaryCreditName', null)) {
        references.push({
          companyName: _.get(values, 'primaryCreditName', null),
          contactName: _.get(values, 'primaryCreditContactName', null),
          contactEmail: _.get(values, 'primaryCreditContactEmail', null),
          contactPhone: _.get(values, 'primaryCreditPhone', null),
          accountNum: _.get(values, 'primaryCreditAccountNum', null),
          referenceType: ReferenceType.Loan,
        });
      }

      if (_getBoolByPath('locInPlace')) {
        references.push({
          referenceType: ReferenceType.Loc,
          creditLimit: _getFloatNumberByPath('locReferenceCreditLimit'),
          currentBalance: _getFloatNumberByPath('locReferenceCurrentBalance'),
          monthlyPayment: _getFloatNumberByPath('locReferenceMonthlyPayment'),
          renewalDate: _.get(values, 'locReferenceRenewalDate', null),
          lineOfCredit: _getBoolByPath(`locReferenceLineOfCredit`),
        });
      }

      const tradeReferenceFields = getTradeReferenceFields();
      return _.concat(references, tradeReferenceFields);
    }

    const getDynamicReferences = (): Array<Maybe<ReferencesInput>> => {
      const getReferenceForPayload = (reference: any): ReferencesInput => {
        return _.reduce(reference, (result: any, value: any, key: string) => {
          if (_.includes(intNumberFields, key)) {
            result[key] = _getIntNumberByValue(value);
            return result;
          }
          if (_.includes(floatNumberFields, key)) {
            result[key] = _getFloatNumberByValue(value);
            return result;
          }
          result[key] = value;
          return result;
        }, {});
      };

      const nonDataFields = ['referenceType', 'referenceIdx', 'ownerIdx'];
      if (_.isEmpty(values.references)) {
        return [];
      }
      return _(values.references)
        .values()
        .filter(ref => // filter for non-empty references
          _.some(ref, (value, key) => {
            if (_.includes(nonDataFields, key)) {
              return false;
            }
            return !!value || value === 0;
          })
        )
        .map(getReferenceForPayload)
        .value();
    }

    const legacyReferences = getLegacyReferences();
    const dynamicReferences = getDynamicReferences();
    return _.concat(legacyReferences, dynamicReferences);
  };

  const getEquipments = (): Array<Maybe<EquipmentInput>> => {
    const fieldsToSkip = ['doYouHaveATradeIn'];
    const intNumberFields = ['hours', 'mileage', 'quantity'];
    const floatNumberFields = ['retailPrice', 'tradeInValue', 'amountOwedOnTradeIn'];
    const stringifiedFields = ['year'];
    const getEquipmentForPayload = (equipment: any): EquipmentInput => {
      return _.reduce(equipment, (result: any, value: any, key: string) => {
        if (value === "") {
          return result;  // to not save empty strings
        }
        if (_.includes(fieldsToSkip, key)) {
          return result;
        }
        if (_.includes(intNumberFields, key)) {
          result[key] = _getIntNumberByValue(value);
          return result;
        }
        if (_.includes(floatNumberFields, key)) {
          result[key] = _getFloatNumberByValue(value);
          return result;
        }
        if (_.includes(stringifiedFields, key)) {
          result[key] = _getStringifiedNumbers(value);
          return result;
        }
        result[key] = value;
        return result;
      }, {});
    };

    const nonDataFields = ['type', 'equipmentIdx'];
    if (_.isEmpty(values.equipments)) {
      return [];
    }
    return _(values.equipments)
      .values()
      .filter(equipment => // filter for non-empty equipments
        _.some(equipment, (value, key) => {
          if (_.includes(nonDataFields, key)) {
            return false;
          }
          return !!value || value === 0;
        })
      )
      .map(getEquipmentForPayload)
      .value();
  };

  const getDocsUpload = () => {
    return _(values.docsUpload).map(doc => {
      const docUpload = _.pick(doc, ['docsUpload', 'fileToUpload', 'docDescription']);
      return _.some(docUpload.fileToUpload)
        ? { ...docUpload, fileToUpload: [docUpload.fileToUpload] }  // fileToUpload needs to be an array
        : null;
    })
      .compact()
      .value();
  };

  const getOwnersData = (): Array<BusinessOwnerInput> | undefined => {

    const processOwnerForPayload = (owner: any): BusinessOwnerInput => {
      const signature = _.get(owner, 'signature', null);
      const personalGuaranteeSignature = _.get(owner, 'personalGuaranteeSignature', null);
      return {
        firstName: _.get(owner, 'firstName', null),
        lastName: _.get(owner, 'lastName', null),
        middleName: _.get(owner, 'middleName', null),
        nameSuffix: _.get(owner, 'nameSuffix', null),
        relationship: _.get(owner, 'relationship', null),
        dlStateIssued: _.get(owner, 'dlStateIssued', null),
        driverLicenseExp: _.get(owner, 'driverLicenseExp', null),
        driversLicenseNum: _.get(owner, 'driverLicenseNum', null),
        homeAddress: _.get(owner, 'homeAddress', null),
        homeCity: _.get(owner, 'homeCity', null),
        homeState: _.get(owner, 'homeState', null),
        homePostalCode: _.get(owner, 'homePostalCode', null),
        homePhone: _.get(owner, 'homePhone', null),
        homeType: _.get(owner, 'homeType', null),
        timeAtAddressYears: _.get(owner, 'timeAtAddressYears', null),
        timeAtAddressMonths: _.get(owner, 'timeAtAddressMonths', null),
        employerName: _.get(owner, 'employerName', null),
        timeAtJobYears: _getIntNumberByValue(_.get(owner, 'timeAtJobYears', null)),
        timeAtJobMonths: _getIntNumberByValue(_.get(owner, 'timeAtJobMonths', null)),
        employmentStatus: _.get(owner, 'employmentStatus', null),

        employerAddress: _.get(owner, 'employerAddress', null),
        employerAddress2: _.get(owner, 'employerAddress2', null),
        employerCity: _.get(owner, 'employerCity', null),
        employerState: _.get(owner, 'employerState', null),
        employerZip: _.get(owner, 'employerZip', null),

        grossMonthlyIncome: _getFloatNumberByValue(_.get(owner, 'grossMonthlyIncome', null)),
        employerPhone: _.get(owner, 'employerPhone', null),
        phone: _.get(owner, 'phone', null),
        address: _.get(owner, 'address', null),
        address2: _.get(owner, 'address2', null),
        city: _.get(owner, 'city', null),
        state: _.get(owner, 'state', null),
        addressCounty: _.get(owner, 'addressCounty', null),
        postalCode: _.get(owner, 'postalCode', null),
        prevAddress: _.get(owner, 'prevAddress', null),
        prevAddress2: _.get(owner, 'prevAddress2', null),
        prevCity: _.get(owner, 'prevCity', null),
        prevState: _.get(owner, 'prevState', null),
        prevCounty: _.get(owner, 'prevCounty', null),
        prevPostalCode: _.get(owner, 'prevPostalCode', null),
        email: _.get(owner, 'email', null),
        percOwner: _getIntNumberByValue(_.get(owner, 'percOwner', null)),
        yearsWithCdl: _getIntNumberByValue(_.get(owner, 'yearsWithCdl', null)),
        yearsAsOwnerOp: _getIntNumberByValue(_.get(owner, 'yearsAsOwnerOp', null)),
        mobilePhone: _.get(owner, 'mobilePhone', null),
        ssn: _getStringifiedNumbers(_.get(owner, 'ssn', null)),
        netWorth: _getFloatNumberByValue(_.get(owner, 'netWorth', null)),
        monthlyHousingPayment: _getFloatNumberByValue(_.get(owner, 'monthlyHousingPayment', null)),
        isOwnerPc: !!_.get(owner, 'isOwnerPc', null),
        isMarketingApproved: !!_.get(owner, 'isOwnerPc', null) ? _.get(values, 'isMarketingApproved', null) : null,
        isOwnerPg: !!_.get(owner, 'isOwnerPg', null) || !!_.get(owner, 'ssn', null),
        isOwnerPcOnly: !!_.get(owner, 'isOwnerPcOnly', null),
        driversLicenseUpload: _.get(owner, 'uploadDLQuestion', null),
        driversLicenseFile: [
          _.get(owner, 'fileInput.docsUpload', null),
        ],
        dlUploaded: _.get(owner, 'uploadDLQuestion') === "yes",
        dateOfBirth: _.get(owner, 'dateOfBirth', null),
        signature: signature,
        signatureDate: signature ? new Date().toISOString() : null,
        personalGuaranteeSignature: personalGuaranteeSignature,
        personalGuaranteeSignatureDate: personalGuaranteeSignature ? new Date().toISOString() : null,
        employerEmail: _.get(owner, 'employerEmail', null),
        title: _.get(owner, 'title', null),
        countryOfResidence: _.get(owner, 'countryOfResidence', null),
        ownershipType: _.get(owner, 'ownershipType', null),
        usCitizen: _getBoolByValue(_.get(owner, 'usCitizen', null)),
        commercialDriverMonths: _getIntNumberByValue(_.get(owner, 'commercialDriverMonths', null)),
        commercialDriverYears: _getIntNumberByValue(_.get(owner, 'commercialDriverYears', null)),
        residentStatus: _.get(owner, 'residentStatus', null),
        providingGuaranty: _getBoolByValue(_.get(owner, 'providingGuaranty', null)),
        maritalStatus: _.get(owner, 'maritalStatus', null),
        companyDriverYears: _getIntNumberByValue(_.get(owner, 'companyDriverYears', null)),
        ownerOperatorYears: _getIntNumberByValue(_.get(owner, 'ownerOperatorYears', null)),
        firstTimeFinancing: _.get(owner, 'firstTimeFinancing', null),
        nearestRelativeFirstName: _.get(owner, 'nearestRelativeFirstName', null),
        nearestRelativeLastName: _.get(owner, 'nearestRelativeLastName', null),
        nearestRelativePhone: _.get(owner, 'nearestRelativePhone', null),
        nearestRelativeCity: _.get(owner, 'nearestRelativeCity', null),
        nearestRelativeZip: _.get(owner, 'nearestRelativeZip', null),
        reasonForDeclining: _.get(owner, 'reasonForDeclining', null),
        addendum: _.get(owner, 'addendum', null),
        ownerPgId: _.get(owner, 'ownerPgId', null), // if we update the owner (e.g. from owner authorization page)
      };
    }

    if (_.isEmpty(values.owners)) {
      return undefined;
    }

    const owners = values.owners.map(processOwnerForPayload);

    return (!draft && !!values.ownerPcOnly)
      ? [...owners, processOwnerForPayload(values.ownerPcOnly)]
      : owners
  };

  let ssnToSend = _getStringifiedNumbers(_.get(values, 'owners[0].ssn', null));
  if (ssnToSend === null) {
    ssnToSend = _getStringifiedNumbers(_.get(values, 'ssn', null));
  }

  // TODO: refactor check for correct uuid format
  const ocaTemplateId = activeTemplate?.ocaTemplateId;
  const partnerId = getURLParameter('partnerId');
  const partnerDealerId = getURLParameter('partnerDealerId');
  let payload: CreateCreditApplicationInput = {
    formId: (formId && validate(formId)) ? formId : null,
    ocaTemplateId: (ocaTemplateId && validate(ocaTemplateId)) ? ocaTemplateId : null,
    locationId: (locationId && validate(locationId)) ? locationId : null,
    //dynamicsContactId: (vendorContactId && validate(vendorContactId)) ? vendorContactId : null,
    //vendorGUID: (vendorGUID && validate(vendorGUID)) ? vendorGUID : null,
    dynamicsContactId: vendorContactId,  // for some reason "validate" doesn't work for vendorGUID. Needs to investigate
    vendorGUID,
    lenderProfileId: (lenderProfileId && validate(lenderProfileId)) ? lenderProfileId : null,
    repId: null,
    draft,
    bizOrIndividual: _.get(values, 'bizOrIndividual', null),
    legalStructure: _.get(values, 'legalStructure', null),
    firstName: _.get(values, 'firstName', null),
    lastName: _.get(values, 'lastName', null),
    middleName: _.get(values, 'middleName', null),
    nameSuffix: _.get(values, 'nameSuffix', null),
    email: _.get(values, 'email', null),
    businessName: _.get(values, 'businessName', null),
    address: _.get(values, 'address', null) ? _.get(values, 'address', null) : _.get(values, 'homeAddress', null),
    presAddress2: _.get(values, 'address2', null),
    city: _.get(values, 'city', null) ? _.get(values, 'city', null) : _.get(values, 'homeCity', null),
    state: _.get(values, 'state', null) ? _.get(values, 'state', null) : _.get(values, 'homeState', null),
    postalCode: _.get(values, 'postalCode', null) ? _.get(values, 'postalCode', null) : _.get(values, 'homePostalCode', null),
    addressCounty: _.get(values, 'addressCounty', null) ? _.get(values, 'addressCounty', null) : _.get(values, 'AddressCounty', null),
    timeAtAddressInYears: _getIntNumberByPath('timeAtAddressYears'),
    timeAtAddressInMonths: _getIntNumberByPath('timeAtAddressMonths'),
    prevAddress: _.get(values, 'prevAddress', null),
    prevCity: _.get(values, 'prevCity', null),
    prevState: _.get(values, 'prevState', null),
    prevCounty: _.get(values, 'prevCounty', null),
    prevPostalCode: _.get(values, 'prevPostalCode', null),
    timeAtPrevAddressYears: _.get(values, 'timeAtPrevAddressYears', null),
    timeAtPrevAddressMonths: _.get(values, 'timeAtPrevAddressMonths', null),
    totalAssets: _getFloatNumberByPath('totalAssets'),
    totalLiabilities: _getFloatNumberByPath('totalLiabilities'),
    incomeAmountPerYearOther: _getFloatNumberByPath('incomeAmountPerYearOther'),
    incomeSourceOther: _.get(values, 'incomeSourceOther', null),
    homeType: _.get(values, 'homeType', null),
    homePhone: _.get(values, 'homePhone', null),
    dba: _.get(values, 'dba', null),
    phone: _.get(values, 'phone', null),
    bizPhone: _.get(values, 'bizPhone', null),
    ccg_transaction_equipmentdescription: _.get(
      values,
      'ccg_transaction_equipmentdescription',
      null
    ),
    businessAddressCounty: _.get(values, 'businessAddressCounty', null),
    priorBankruptcy: _.get(values, 'priorBankruptcy', null),
    numOfEmployees: _getIntNumberByPath('numOfEmployees'),
    billingAddress: _.get(values, 'billingAddress', null),
    billingCity: _.get(values, 'billingCity', null),
    billingState: _.get(values, 'billingState', null),
    billingPostalCode: _.get(values, 'billingPostalCode', null),
    contractorLicenseNum: _.get(values, 'contractorLicenseNum', null),
    amountRequested: _getFloatNumberByPath('amountRequested')
      || _getFloatNumberByPath('equipmentFinancing'), // for backward compatibility
    creditLineAmountRequested: _getFloatNumberByPath('creditLineAmountRequested'),
    percOwner: _getIntNumberByPath('owners[0].percOwner'),
    ssn: ssnToSend,
    applicationType: ApplicationType.Commercial,
    dateEstablished: _getDateEstablished(),
    yearsInBusiness: _getIntNumberByPath('yearsInBusiness'),
    dateIncorporated: _getDateIncorporated(),
    annualRevenue: _getFloatNumberByPath('annualRevenue'),
    currentOwnerStartBusiness: _getBoolByPath('currentOwnerStartBusiness'),
    taxId: _getIntNumberByPath('taxId'),
    salesTaxExempt: _getBoolByPath('salesTaxExempt'),
    lienOrJudgement: _getBoolByPath('lienOrJudgement'),
    lienOrJudgementDetail: _.get(values, 'lienOrJudgementDetail', null),
    apContactName: _.get(values, 'apContactName', null),
    apContactEmail: _.get(values, 'apContactEmail', null),
    apContactPhone: _.get(values, 'apContactPhone', null),
    primaryBankAverageBalance: _getIntNumberByPath('primaryBankAverageBalance'),
    ssnExempt: _getBoolByPath('ssnExempt'),
    ocaLanguage: 'en',
    partnerId: (partnerId && validate(partnerId)) ? partnerId : null,
    partnerDealerId: (partnerDealerId && validate(partnerDealerId)) ? partnerDealerId : null,
    equipmentCategoryCode: null,
    insuranceWaiver: _.get(values, 'insuranceWaiver', null),
    addendum: _.get(values, 'addendum', null),
    ocaType: `OCAv4 - ${_.get(activeTemplate, 'name', 'no-active-template-found')} - ${_determineChannel()}`.substring(
      0,
      99
    ),
    industryType: _.get(values, 'industryType', null),

    employerName: _.get(values, 'employerName', null),
    employerEmail: _.get(values, 'employerEmail', null),
    employerAddress: _.get(values, 'employerAddress', null),
    employerAddress2: _.get(values, 'employerAddress2', null),
    employerCity: _.get(values, 'employerCity', null),
    employerState: _.get(values, 'employerState', null),
    employerZip: _.get(values, 'employerZip', null),
    employerPhone: _.get(values, 'employerPhone', null),
    periodOfEmploymentYears: _getIntNumberByPath('timeAtJobYears'),
    periodOfEmploymentMonths: _getIntNumberByPath('timeAtJobMonths'),
    monthlyHousingPayment: _getFloatNumberByPath('monthlyHousingPayment'),
    rawValues: JSON.stringify(values),
    // External IDs
    extDmsOpportunityId,
  };

  if (APP_ENV === 'development' || !APP_ENV) {
    payload.ocaEnv = 100;
  } else if (APP_ENV === 'production') {
    payload.ocaEnv = 200;
  } else if (APP_ENV === 'stage') {
    payload.ocaEnv = 300;
  }

  const dateOfBirthTopLevelValue = _.get(values, 'dateOfBirth', null);
  if (dateOfBirthTopLevelValue) {
    payload.dateOfBirth = dateOfBirthTopLevelValue;
  }

  payload.driversLicenseUpload = _.some(values.owners, o => !!_.get(o, 'fileInput.name', false))
    ? 'yes'
    : 'no';
  payload.owners = getOwnersData();

  if (
    values.legalStructure === '803370000' ||
    values.legalStructure === '100000001'
  ) {
    delete values.dateIncorporated;
  }

  _.get(values, 'uploadDLQuestion', null);
  //Overloads ssn & percOwner based on SSN Exempt value and Legal Structure
  if (
    payload.ssnExempt &&
    (values.legalStructure === '803370001' ||
      values.legalStructure === '803370002' ||
      values.legalStructure === '803370003' ||
      values.legalStructure === '100000000')
  ) {
    payload.ssn = null;
    payload.percOwner = 0;
    _.set(payload, 'owners[0].percOwner', 0);
  }

  payload.homeSameAsBiz = _getBoolByPath('homeSameAsBiz');
  payload.monthlyIncome = _getFloatNumberByPath('monthlyIncome') ? _getFloatNumberByPath('monthlyIncome') : _getFloatNumberByPath('grossMonthlyIncome');
  payload.existingCustomer = _getBoolByPath('existingCustomer');
  payload.primaryCreditName = _.get(values, 'primaryCreditName', null);
  payload.primaryCreditContactName = _.get(
    values,
    'primaryCreditContactName',
    null
  );
  payload.primaryCreditContactEmail = _.get(
    values,
    'primaryCreditContactEmail',
    null
  );
  payload.primaryCreditPhone = _.get(values, 'primaryCreditPhone', null);
  payload.primaryCreditAccountNum = _.get(
    values,
    'primaryCreditAccountNum',
    null
  );
  payload.primaryCreditAverageBalance = _getIntNumberByPath(
    'primaryCreditAverageBalance'
  );

  payload.primaryBankPhone = _.get(values, 'primaryBankPhone', null);
  payload.primaryBankAccountNum = _.get(values, 'primaryBankAccountNum', null);
  payload.primaryBankContactName = _.get(
    values,
    'primaryBankContactName',
    null
  );
  payload.homePhone = _.get(values, 'homePhone', null) ? _.get(values, 'homePhone', null) : _.get(values, 'owners[0].homePhone', null);
  payload.homeCity = _.get(values, 'owners[0].homeCity', null);
  payload.homeState = _.get(values, 'owners[0].homeState', null);
  payload.homePostalCode = _.get(values, 'owners[0].homePostalCode', null);
  payload.homeCounty = _.get(values, 'owners[0].homeCounty', null);
  payload.homeType = _.get(values, 'homeType', _.get(values, 'owners[0].homeType', null));
  payload.primaryBankName = _.get(values, 'primaryBankName', null);
  //payload.signature = getSignatureOrTitleForPayload('signature');
  //payload.title = getSignatureOrTitleForPayload('title');
  //payload.personalGuaranteeSignature = getSignatureOrTitleForPayload('personalGuaranteeSignature');
  payload.fleetSegments = _.get(values, 'fleetSegments', null);
  payload.seasonalBusiness = _getBoolByPath('seasonalBusiness');
  payload.inactiveMonths = _getMultiSelectValues('inactiveMonths');
  payload.fleetOperation = _.get(values, 'fleetOperation', null);
  payload.annualMileage = _getIntNumberByPath('annualMileage');
  payload.operatingLine = _.get(values, 'operatingLine', null);
  payload.piecesOfTractorEqpt = _getIntNumberByPath('piecesOfTractorEqpt');
  payload.piecesOfTruckEqpt = _getIntNumberByPath('piecesOfTruckEqpt');
  payload.piecesOfUnitsPurchased = _getIntNumberByPath('piecesOfUnitsPurchased');
  payload.numberOfAdditions = _getIntNumberByPath('numberOfAdditions');
  payload.numberOfReplacements = _getIntNumberByPath('numberOfReplacements');

  payload.nearestRelativeFirstName = _.get(
    values,
    'nearestRelativeFirstName',
    null
  );
  payload.nearestRelativeLastName = _.get(
    values,
    'nearestRelativeLastName',
    null
  );

  payload.nearestRelativePhone = _.get(values, 'nearestRelativePhone', null);
  payload.nearestRelativeCity = _.get(values, 'nearestRelativeCity', null);
  payload.nearestRelativeState = _.get(values, 'nearestRelativeState', null);
  payload.nearestRelativeZip = _.get(values, 'nearestRelativeZip', null);
  payload.downPaymentAmount = _getIntNumberByPath('downPaymentAmount');
  payload.termRequested = _getIntNumberByPath('termRequested');
  payload.agriculturalExempt = _getBoolByPath('agriculturalExempt');
  payload.eqptUse = _.get(values, 'eqptUse', null);
  payload.typesOfGoods = _.get(values, 'typesOfGoods', null);
  payload.longHaulRegional = _.get(values, 'longHaulRegional', null);
  payload.hazmat = _getBoolByPath('hazmat');
  payload.industryExpYears = _getIntNumberByPath('industryExpYears');
  payload.ownAuth = _getBoolByPath('ownAuth');
  payload.typesOfGoods = _.get(values, 'typesOfGoods', null);
  payload.mcNumber = _.get(values, 'mcNumber', null);
  payload.usDotNum = _.get(values, 'usDotNum', null);
  payload.piecesOfHeavyEqpt = _getIntNumberByPath('piecesOfHeavyEqpt');
  payload.piecesOfMediumEqpt = _getIntNumberByPath('piecesOfMediumEqpt');
  payload.piecesOfTrailerEqpt = _getIntNumberByPath('piecesOfTrailerEqpt');
  payload.numOfOwnerOps = _getIntNumberByPath('numOfOwnerOps');
  payload.previousFinanceExp = _.get(values, 'previousFinanceExp', null);
  payload.eqptPaidOff = _getBoolByPath('eqptPaidOff');
  payload.eqptTrade = _getBoolByPath('eqptTrade');
  payload.eqptAddOrReplace = _.get(values, 'eqptAddOrReplace', null);
  payload.applyingFor = getApplyingForValue();
  payload.stateOfIncorp = _.get(values, 'stateOfIncorp', null);
  payload.invoiceDelivery = _.get(values, 'invoiceDelivery', null);
  payload.purchaseOrder = _.get(values, 'purchaseOrder', null);
  payload.primaryAgriculturalProduct = _.get(values, 'primaryAgriculturalProduct', null);
  payload.farmingStartYear = _getIntNumberByPath('farmingStartYear');
  payload.farmType = _.get(values, 'farmType', null);
  payload.farmingIncome = _getFloatNumberByPath('farmingIncome');
  payload.nonFarmingIncome = _getFloatNumberByPath('nonFarmingIncome');
  payload.businessType = _.get(values, 'businessType', null);
  payload.bankruptcyDate = _.get(values, 'bankruptcyDate', null);
  payload.previousEmployerPeriodOfEmploymentYears = _getIntNumberByPath('previousEmployerPeriodOfEmploymentYears');
  payload.previousEmployerName = _.get(values, 'previousEmployerName', null);
  payload.farmingStatus = _.get(values, 'farmingStatus', null);
  payload.kubotaPrincipalUse = _.get(values, 'kubotaPrincipalUse', null);
  payload.useByApplicant = _getBoolByPath('useByApplicant');
  payload.disclosure = _.get(values, 'disclosure', null);
  payload.applicantType = _.get(values, 'applicantType', null);
  payload.hadItemRepossessed = _getBoolByPath('hadItemRepossessed');
  payload.repossessionDetail = _.get(values, 'repossessionDetail', null);
  payload.pastExperience = _.get(values, 'pastExperience', null);
  payload.rentalHouse = _.get(values, 'rentalHouse', null);
  payload.firstTimeBuyer = _getBoolByPath('firstTimeBuyer');
  payload.insuranceWaiverContent = _.get(values, 'insuranceWaiver', null) === 'accept'
    ? _.get(values, 'insuranceWaiverContent', null)
    : null;
  payload.addendumContent = _.get(values, 'addendum', null) === 'accept'
    ? _.get(values, 'addendumContent', null)
    : null;
  payload.providingInsurance = _.get(values, 'providingInsurance', null);
  payload.purchaseTimeframe = _.get(values, 'purchaseTimeframe', null);
  payload.authorizedParties = _.get(values, 'authorizedParties', null);
  payload.workingCapitalRequestedAmount = _getFloatNumberByPath('workingCapitalRequestedAmount');
  payload.averageDailyBalance = _getFloatNumberByPath('averageDailyBalance');
  payload.workingCapitalOffer = _.get(values, 'workingCapitalOffer', null);
  payload.equipmentNumInFleet = _getIntNumberByPath('equipmentNumInFleet');
  payload.truckType = _.get(values, 'truckType', null);
  payload.parentCompany = _.get(values, 'parentCompany', null);
  payload.country = _.get(values, 'country', null);
  payload.attemptToSubmit = attemptToSubmit;
  payload.source = source;
  payload.sendCreditApplicationSignaturesTasks = _.get(values, 'sendCreditApplicationSignaturesTasks', null);
  payload.sendPersonalGuaranteeSignaturesTasks = _.get(values, 'sendPersonalGuaranteeSignaturesTasks', null);
  payload.sendDocumentsSignaturesTasks = _.get(values, 'sendDocumentsSignaturesTasks', null);
  payload.docsUpload = getDocsUpload();
  payload.references = getReferences();
  payload.equipments = getEquipments();

  return payload;
};
