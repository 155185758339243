import {
  Box,
  Grid,
  TextField,
  Autocomplete
} from '@mui/material';
import { FormikProps, useFormikContext } from 'formik';
import _ from 'lodash';
import { useCallback, useContext, useState, useEffect } from 'react';
import { FieldType } from '../types';
import { CommonDataContext } from '../../context';
import useAutocomplete from '../../hooks/useAutocomplete';

const AutocompleteField = ({
  itemData,
  locale,
  value,
  externalData,
}: {
  itemData: FieldType;
  locale: string;
  value: any;
  externalData: any;
}) => {
  const { config: fieldConfig } = itemData;
  const formik: FormikProps<any> = useFormikContext();
  const { isMobileView, highlightErrorFieldsState } = useContext(CommonDataContext);

  const { fieldOptions } = useAutocomplete(itemData, externalData?.sectionConfig);

  const [isInvalid, setIsInvalid] = useState(false);

  useEffect(() => {
    if (value) {
      setIsInvalid(evaluateIfInvalid(value));
    }
    return () => {
      formik.setFieldError(itemData.config.fieldName, '');
    };
  }, []);

  useEffect(() => {
    //When there is no need to display the field, we reset it's state to a clean one
    if (!itemData.config.displayed) {
      _resetField();
    } else {
      let isEmpty = false;
      if (itemData.config.required) {
        isEmpty = !checkIfValueSet(value)
      }

      formik.setFieldError(
        itemData.config.fieldName,
        isInvalid || isEmpty ? 'required' : ''
      );
    }
  }, [itemData.config.displayed, itemData.config.required, isInvalid, value]);

  useEffect(() => {
    if (highlightErrorFieldsState.state) {
      setIsInvalid(!!formik.getFieldMeta(itemData.config.fieldName).error);
    }
  }, [highlightErrorFieldsState]);

  const checkIfValueSet = (value: string | number | boolean): boolean => _.isBoolean(value) || !!value || value === 0;

  const evaluateIfInvalid = (value: string | number | boolean): boolean => {
    const isValueSet = checkIfValueSet(value);
    const isRequiredAndEmpty = !!fieldConfig.required && !isValueSet;
    return isRequiredAndEmpty;
  };

  const handleChange = useCallback(
    (e, selectedOption) => {
      const optionValue = _.get(selectedOption, 'optionValue', '');
      formik.setFieldValue(itemData.config.fieldName, optionValue, false);
      setIsInvalid(evaluateIfInvalid(optionValue));
    },
    [formik]
  );

  const _resetField = (): void => {
    // Check if error is already set to ''
    if (Boolean(_.get(formik.errors, itemData.config.fieldName))) {
      //Remove Error
      formik.setFieldError(itemData.config.fieldName, '');
    }
    if (!_.has(formik.values, itemData.config.fieldName)) { // do not set '' value for untouched fields
      return;
    }
    //Erase value
    formik.setFieldValue(itemData.config.fieldName, null);
  };

  return (
    <Box
      component={Grid}
      item
      xl={_.get(fieldConfig, 'columns.xl', 6)}
      lg={_.get(fieldConfig, 'columns.lg', 6)}
      md={_.get(fieldConfig, 'columns.md', 6)}
      sm={_.get(fieldConfig, 'columns.sm', 6)}
      xs={_.get(fieldConfig, 'columns.xs', 6)}
      display={{
        xl: !itemData.config.displayed ? 'none' : 'block',
        lg: !itemData.config.displayed ? 'none' : 'block',
        md: !itemData.config.displayed ? 'none' : 'block',
        sm: !itemData.config.displayed ? 'none' : 'block',
        xs: !itemData.config.displayed ? 'none' : 'block',
      }}
    >
      <Autocomplete
        {...fieldConfig.inputProps}
        id={fieldConfig.fieldName}
        value={_.find(fieldOptions, { optionValue: value }) || ''}
        options={fieldOptions}
        getOptionLabel={(option) => _.get(option, `displayLabel.${locale}`, '') || _.get(option, 'optionLabel', '')}
        onChange={handleChange}
        renderInput={(params) =>
          <TextField {...params}
            label={fieldConfig.displayLabel?.en}
            variant={isMobileView ? "outlined" : "standard"}
            required={fieldConfig.required}
            error={isInvalid}
          />
        }
      />
    </Box>
  );
};

export { AutocompleteField };
