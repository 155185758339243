/**
 * FIELDS
 */
type LangType = {
  en: string;
  es: string;
};

type ColumnType = {
  xl: number;
  lg: number;
  md: number;
  sm: number;
  xs: number;
};
type FieldOptionsType = {
  optionLabel?: string;
  optionValue: string | number | boolean;
  displayLabel: LangType;
  metaData?: any;
};
type FieldConfigValuesListType = {
  label: string;
  value: string;
};

type FieldConfigYearsSettingsType = {
  maxYear: number | "";
  minYear: number | "";
  yearsInAdvance: number;
};

type FieldConfigType = {
  customMarkup?: string;
  selectedSource?: "Custom Markup" | "VP - Personal Guarantee Agreement Disclosure" | undefined;
  selectedApiId?: string;
  headerContent?: string;
  mainContent?: string;
  text?: string;
  type?: string;
  fieldName: string;
  fieldLabel?: string;
  helperText?: LangType;
  columns?: ColumnType;
  variant?: "standard" | "filled" | "outlined" | undefined;
  valuesList?: Array<FieldConfigValuesListType>;
  fullWidth?: boolean;
  useDropdown?: boolean;
  required?: Required<boolean>;
  disabled?: Required<boolean>;
  displayed: Required<boolean>;
  errorMessage?: LangType;
  minValue?: any;
  minValuePerOwner?: number;
  maxValue?: any;
  multipleSelectEnabled?: boolean;
  displayLabel?: LangType;
  style?: React.CSSProperties | undefined;
  fieldOptions?: Array<FieldOptionsType>;
  disclosureLanguage?: string;
  inputProps?: any;
  yearsSettings?: FieldConfigYearsSettingsType;
  signaturesRequiredSetting?: "all-owners-require-to-sign" | "one-owner-require-to-sign" | "one-owner-or-poc-require-to-sign";
  signaturesSignorsSetting?: "not-set" | "add-option-to-send-signature-task-for-non-signors" | "auto-create-signature-task-for-non-signors" | "auto-create-signature-task-for-each-signor";
  signaturesRequireAgreementsOfElectronicSignatureMessage?: string;
  signaturesRequireAgreementsOfElectronicSignature?: boolean;
  maskedView?: boolean;
  maxLength?: number | string;
};

type RulesTypes = "default" | "autofill" | "required" | undefined;
type RulesSatisfactionType = "all" | "at_least_one" | "exactly_one" | "none";

type RuleType = {
  type: RulesTypes;
  targetName: Pick<FieldConfigType, "fieldName">;
  scope?: "local" | "global";
  evalType:
  | "have_value"
  | "contain"
  | "have_not_value"
  | "lt"
  | "lte"
  | "eq"
  | "gt"
  | "gte"
  | "neq";
  evalValue: any;
  valueToAutofill: any;
  isNeedToHideOnAutofill: boolean;
};

type SectionTypes = DynamicReferenceSectionType | DynamicSignaturesSectionType | PersonalGuaranteeSignaturesSectionType | DocumentSignSectionType;
type DynamicReferenceSectionType = "dynamic-reference";
type DynamicSignaturesSectionType = "dynamic-signatures";
type PersonalGuaranteeSignaturesSectionType = "personal-guarantee-signatures";
type DocumentSignSectionType = "document-sign";

type FieldType = {
  id: string;
  type: string;
  originalType?: string;
  config: FieldConfigType;
  rules: Array<RuleType>;
  rulesSatisfactionType?: RulesSatisfactionType;
  sectionTypes?: Array<DynamicReferenceSectionType>;
};

/**
 * SECTIONS
 */

type SectionTitleType = {
  type: string | undefined;
  config: React.CSSProperties & FieldConfigType;
  id: string;
};

type SectionConfigType = {
  requireAcceptanceToProceed?: boolean;
  requireSigning?: boolean;
  useMultipleSigning?: boolean;
};

type SectionType = {
  type: string;
  title: SectionTitleType;
  fields: Array<FieldType>;
  rules: Array<RuleType>;
  config: SectionConfigType & Pick<FieldConfigType, "disabled" | "displayed">;
  rulesSatisfactionType?: RulesSatisfactionType;
  sectionType?: SectionTypes;
  referenceType?: string;
  referenceIdx?: string;
  equipmentType?: "PURCHASE" | "TRADE_IN" | "VIEW_ONLY";
  requireTermsAndConditionsView?: boolean;
};
type FormGenericValues = {
  values: any;
  handleChange: any;
};

type Task = {
  regarding?: String;
  taskItemId: String;
  docType: String;
  active: boolean;
  taskItems: TaskItem[];
};

type TaskItem = {
  type: "documentESign";
};

type LenderProfile = {
  insuranceRequirements: string;
  name: string;
}

enum CountryCodes {
  US = "US",
  CANADA = "CANADA"
};

export {
  FieldType,
  FieldConfigType,
  FieldOptionsType,
  FormGenericValues,
  SectionType,
  SectionTitleType,
  FieldConfigValuesListType,
  RuleType,
  RulesTypes,
  RulesSatisfactionType,
  DynamicReferenceSectionType,
  Task,
  LenderProfile,
  FieldConfigYearsSettingsType,
  CountryCodes
};
