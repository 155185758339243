import { CreditApplication } from "@trnsact/trnsact-shared-types";

export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  JSON: any;
  JSONObject: any;
  Upload: any;
  Date: any;
};

export type BusinessOwnerInput = {
  ownerPgId?: Maybe<Scalars['String']>;
  creditApplicationId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  addressCounty?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  percOwner?: Maybe<Scalars['Int']>;
  ssn?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['String']>;
  homeAddress?: Maybe<Scalars['String']>;
  homeCity?: Maybe<Scalars['String']>;
  homeState?: Maybe<Scalars['String']>;
  homePostalCode?: Maybe<Scalars['String']>;
  driversLicenseNum?: Maybe<Scalars['String']>;
  dlStateIssued?: Maybe<Scalars['String']>;
  homeType?: Maybe<Scalars['String']>;
  homePhone?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  timeAtAddressYears?: Maybe<Scalars['String']>;
  timeAtAddressMonths?: Maybe<Scalars['String']>;
  netWorth?: Maybe<Scalars['Float']>;
  grossMonthlyIncome?: Maybe<Scalars['Float']>;
  monthlyHousingPayment?: Maybe<Scalars['Float']>;
  employerName?: Maybe<Scalars['String']>;
  timeAtJobYears?: Maybe<Scalars['Int']>;
  timeAtJobMonths?: Maybe<Scalars['Int']>;
  mobilePhone?: Maybe<Scalars['String']>;
  driversLicenseUpload?: Maybe<Scalars['String']>;
  driversLicenseFile?: Maybe<Scalars['JSON']>;
  signature?: Maybe<Scalars['String']>;
  signatureDate?: Maybe<Scalars['String']>;
  dlUploaded?: Maybe<Scalars['Boolean']>;
  middleName?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  employerPhone?: Maybe<Scalars['String']>;
  periodOfEmploymentYears?: Maybe<Scalars['Int']>;
  periodOfEmploymentMonths?: Maybe<Scalars['String']>;
  relationship?: Maybe<Scalars['String']>;
  isOwnerPc?: Maybe<Scalars['Boolean']>;
  isMarketingApproved?: Maybe<Scalars['Boolean']>;
  isOwnerPg?: Maybe<Scalars['Boolean']>;
  isOwnerPcOnly?: Maybe<Scalars['Boolean']>;
  employerAddress?: Maybe<Scalars['String']>;
  employerAddress2?: Maybe<Scalars['String']>;
  employerCity?: Maybe<Scalars['String']>;
  employerState?: Maybe<Scalars['String']>;
  employerZip?: Maybe<Scalars['String']>;
  employmentStatus?: Maybe<Scalars['String']>;
  driverLicenseExp?: Maybe<Scalars['String']>;
  workPhone?: Maybe<Scalars['String']>;
  yearsWithCdl?: Maybe<Scalars['Int']>;
  yearsAsOwnerOp?: Maybe<Scalars['Int']>;
  employerEmail?: Maybe<Scalars['String']>;
  countryOfResidence?: Maybe<Scalars['String']>;
  ownershipType?: Maybe<Array<Maybe<OwnershipType>>>;
  usCitizen?: Maybe<Scalars['Boolean']>;
  commercialDriverMonths?: Maybe<Scalars['Int']>;
  commercialDriverYears?: Maybe<Scalars['Int']>;
  residentStatus?: Maybe<ResidentStatus>;
  providingGuaranty?: Maybe<Scalars['Boolean']>;
  maritalStatus?: Maybe<MaritalStatus>;
  companyDriverYears?: Maybe<Scalars['Int']>;
  ownerOperatorYears?: Maybe<Scalars['Int']>;
  firstTimeFinancing?: Maybe<Scalars['Boolean']>;
  nearestRelativeFirstName?: Maybe<Scalars['String']>;
  nearestRelativeLastName?: Maybe<Scalars['String']>;
  nearestRelativePhone?: Maybe<Scalars['String']>;
  nearestRelativeCity?: Maybe<Scalars['String']>;
  nearestRelativeZip?: Maybe<Scalars['String']>;
  prevAddress?: Maybe<Scalars['String']>;
  prevAddress2?: Maybe<Scalars['String']>;
  prevCity?: Maybe<Scalars['String']>;
  prevState?: Maybe<Scalars['String']>;
  prevCounty?: Maybe<Scalars['String']>;
  prevPostalCode?: Maybe<Scalars['String']>;
  reasonForDeclining?: Maybe<Scalars['String']>;
  ownerNumber?: Maybe<Scalars['Int']>;
  personalGuaranteeSignature?: Maybe<Scalars['String']>;
  personalGuaranteeSignatureDate?: Maybe<Scalars['String']>;
  addendum?: Maybe<Scalars['String']>;
};

export type LenderProfileType = {
  ocaBranding: LenderProfleOcaBrandingEnum;
  ocaTemplate: LenderProfleOcaTemplateEnum;
  ocaLenderUserProfileId: Maybe<Scalars['String']>;
  ocaDealerUserProfileId: Maybe<Scalars['String']>;
};

export enum ApplicationType {
  Commercial = 'COMMERCIAL',
  CommercialWithGuarantor = 'COMMERCIAL_WITH_GUARANTOR',
  CommercialWithMultipleGuarantors = 'COMMERCIAL_WITH_MULTIPLE_GUARANTORS',
  Individual = 'INDIVIDUAL',
  IndividualWithSecondGuarantor = 'INDIVIDUAL_WITH_SECOND_GUARANTOR',
}

export enum EquipmentCondition {
  New = 'new',
  Used = 'used',
  Reconditioned = 'reconditioned',
  Demo = 'demo',
}

export enum MedicalPractice {
  Doctor = 'doctor',
  Dentist = 'dentist',
  Veterinarian = 'veterinarian',
  Optometrist = 'optometrist',
  Podiatrist = 'podiatrist',
  Other = 'other',
}

export enum BizOrIndividual {
  Individual = 'individual',
  Business = 'business',
}

export enum IndustryType {
  Physician = 'physician',
  DentistOrthodontist = 'dentist_orthodontist',
  Veterinarian = 'veterinarian',
  Optometrist = 'optometrist',
  Podiatrist = 'podiatrist',
  Other = 'other',
  Agriculture = 'agriculture',
  Construction = 'construction',
  Forestry = 'forestry',
  Manufacturing = 'manufacturing',
  Mining = 'mining',
  Transportation = 'transportation',
  Beverage = 'beverage',
  Crane = 'crane',
  Delivery = 'delivery',
  FoodGrocery = 'food_grocery',
  HazardousToxic = 'hazardous_toxic',
  MaterialHandling = 'material_handling',
  Mixer = 'mixer',
  Municipal = 'municipal',
  Refuse = 'refuse',
  RentalHouse = 'rental_house',
  Tank = 'tank',
  TowRecovery = 'tow_recovery',
  Landscaping = 'landscaping',
  Oilfield = 'oilfield'
}

export enum CreditLineStatus {
  Pending = 'PENDING',
  Approved = 'APPROVED',
  Declined = 'DECLINED',
}

export enum OwnershipType {
  Ownership = 'ownership',
  EffectiveControl = 'effective_control',
  ExecutiveManagement = 'executive_management',
}

export enum OperatingLine {
  AccountsReceivable = 'accounts_receivable',
  AllAssets = 'all_assets',
  PersonalGuaranty = 'personal_guaranty',
  Other = 'other',
}

export enum FleetSegments {
  GeneralFreight = 'general_freight',
  Truckload = 'truckload',
  LessTheTruckload = 'less_the_truckload',
  Other = 'other',
}

export enum FleetOperation {
  Local = 'local',
  Regional = 'regional',
  Interstate = 'interstate',
  Intrastate = 'intrastate',
}

export enum Months {
  Jan = '01',
  Feb = '02',
  Mar = '03',
  Apr = '04',
  May = '05',
  Jun = '06',
  Jul = '07',
  Aug = '08',
  Sep = '09',
  Oct = '10',
  Nov = '11',
  Dec = '12',
}

export enum ApplicantTypes {
  OwnerOperator = 'owner_operator',
  Vocational = 'vocational',
  Fleet = 'fleet',
  Ag = 'ag',
  NonCommercialUse = 'non_commercial_use',
  CommercialUse = 'commercial_use',
}

export enum ProvidingInsurance {
  ReceiveQuote = 'receive_quote',
  ProvideOwn = 'provide_own',
}

export enum TaskStatuses {
  Open = 'open',
  Complete = 'complete',
}

export type CreateCreditApplicationInput = {
  leaseApplication?: Maybe<Scalars['Boolean']>;
  amountRequested?: Maybe<Scalars['Float']>;
  creditLineAmountRequested?: Maybe<Scalars['Float']>;
  creditLineStatus?: Maybe<CreditLineStatus>;
  ccg_transaction_equipmentdescription?: Maybe<Scalars['String']>;
  ocaTemplateId?: Maybe<Scalars['String']>;
  bizOrIndividual?: Maybe<BizOrIndividual>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  applicationType?: Maybe<ApplicationType>;
  businessName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  addressCounty?: Maybe<Scalars['String']>;
  legalStructure?: Maybe<Scalars['String']>;
  tib?: Maybe<Scalars['String']>;
  repId?: Maybe<Scalars['String']>;
  vendorGUID?: Maybe<Scalars['String']>;
  lenderProfileId?: Maybe<Scalars['String']>;
  dynamicsContactId?: Maybe<Scalars['String']>;
  transactionGUID?: Maybe<Scalars['String']>;
  medicalPractice?: Maybe<MedicalPractice>;
  industryType?: Maybe<IndustryType>;
  yearsLicensed?: Maybe<Scalars['Int']>;
  owners?: Maybe<Array<Maybe<BusinessOwnerInput>>>;
  dcrType?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  dba?: Maybe<Scalars['String']>;
  signature?: Maybe<Scalars['String']>;
  ssn?: Maybe<Scalars['String']>;
  ssnExempt?: Maybe<Scalars['Boolean']>;
  percOwner?: Maybe<Scalars['Int']>;
  businessType?: Maybe<Scalars['String']>;
  parentCompany?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  dateEstablished?: Maybe<Scalars['String']>;
  taxId?: Maybe<Scalars['Int']>;
  monthlyRevenue?: Maybe<Scalars['Float']>;
  industryExpYears?: Maybe<Scalars['Int']>;
  monthlyIncome?: Maybe<Scalars['Float']>;
  previousFinanceExp?: Maybe<Scalars['String']>;
  trucksOwned?: Maybe<Scalars['Int']>;
  bizPhone?: Maybe<Scalars['String']>;
  homeSameAsBiz?: Maybe<Scalars['Boolean']>;
  homeAddress?: Maybe<Scalars['String']>;
  homeCity?: Maybe<Scalars['String']>;
  homeState?: Maybe<Scalars['String']>;
  homePostalCode?: Maybe<Scalars['String']>;
  equipmentDescription?: Maybe<Scalars['String']>;
  stockId?: Maybe<Scalars['String']>;
  stateOfIncorp?: Maybe<Scalars['String']>;
  businessAddressCounty?: Maybe<Scalars['String']>;
  driversLicenseNum?: Maybe<Scalars['String']>;
  dlStateIssued?: Maybe<Scalars['String']>;
  homeType?: Maybe<Scalars['String']>;
  homePhone?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['String']>;
  annualRevenue?: Maybe<Scalars['Float']>;
  annualIncome?: Maybe<Scalars['Float']>;
  ocaType?: Maybe<Scalars['String']>;
  billingAddress?: Maybe<Scalars['String']>;
  billingCity?: Maybe<Scalars['String']>;
  billingState?: Maybe<Scalars['String']>;
  billingPostalCode?: Maybe<Scalars['String']>;
  currentOwnerStartBusiness?: Maybe<Scalars['Boolean']>;
  lienOrJudgement?: Maybe<Scalars['Boolean']>;
  lienOrJudgementDetail?: Maybe<Scalars['String']>;
  yearsInBusiness?: Maybe<Scalars['Int']>;
  pastExperience?: Maybe<Scalars['String']>;
  numOfEmployees?: Maybe<Scalars['Int']>;
  salesTaxExempt?: Maybe<Scalars['Boolean']>;
  priorBankruptcy?: Maybe<Scalars['String']>;
  apContactName?: Maybe<Scalars['String']>;
  apContactPhone?: Maybe<Scalars['String']>;
  apContactEmail?: Maybe<Scalars['String']>;
  primaryBankDeposit?: Maybe<Scalars['String']>;
  primaryBankCreditFacility?: Maybe<Scalars['String']>;
  tradeReference?: Maybe<Scalars['String']>;
  dateIncorporated?: Maybe<Scalars['String']>;
  contractorLicenseNum?: Maybe<Scalars['String']>;
  existingCustomer?: Maybe<Scalars['Boolean']>;
  primaryBankName?: Maybe<Scalars['String']>;
  primaryBankContactName?: Maybe<Scalars['String']>;
  primaryBankPhone?: Maybe<Scalars['String']>;
  primaryBankAccountNum?: Maybe<Scalars['String']>;
  primaryBankAverageBalance?: Maybe<Scalars['Int']>;
  tradeReferenceName?: Maybe<Scalars['String']>;
  tradeReferenceAccountNum?: Maybe<Scalars['String']>;
  tradeReferencePhone?: Maybe<Scalars['String']>;
  tradeReferenceFax?: Maybe<Scalars['String']>;
  primaryCreditName?: Maybe<Scalars['String']>;
  primaryCreditContactName?: Maybe<Scalars['String']>;
  primaryCreditContactEmail?: Maybe<Scalars['String']>;
  primaryCreditPhone?: Maybe<Scalars['String']>;
  primaryCreditAccountNum?: Maybe<Scalars['String']>;
  primaryCreditAverageBalance?: Maybe<Scalars['Int']>;
  priorTruckingFinanceExp?: Maybe<Scalars['String']>;
  equipmentNumInFleet?: Maybe<Scalars['Int']>;
  sic?: Maybe<Scalars['String']>;
  insuranceWaiver?: Maybe<Scalars['String']>;
  driversLicenseFile?: Maybe<Scalars['Upload']>;
  driversLicenseUpload?: Maybe<Scalars['String']>;
  formId?: Maybe<Scalars['ID']>;
  draft?: Maybe<Scalars['Boolean']>;
  monthlyHousingPayment?: Maybe<Scalars['Float']>;
  presAddress2?: Maybe<Scalars['String']>;
  insuranceCompanyName?: Maybe<Scalars['String']>;
  dunsNumber?: Maybe<Scalars['String']>;
  bankAccountType?: Maybe<Scalars['String']>;
  bankAddress?: Maybe<Scalars['String']>;
  bankAddressLine2?: Maybe<Scalars['String']>;
  bankAddressZip?: Maybe<Scalars['String']>;
  bankAddressCity?: Maybe<Scalars['String']>;
  bankAddressState?: Maybe<Scalars['String']>;
  accountantName?: Maybe<Scalars['String']>;
  accountantPhone?: Maybe<Scalars['String']>;
  accountantFax?: Maybe<Scalars['String']>;
  accountantAddress?: Maybe<Scalars['String']>;
  accountantAddress2?: Maybe<Scalars['String']>;
  accountantZip?: Maybe<Scalars['String']>;
  accountantCity?: Maybe<Scalars['String']>;
  accountantState?: Maybe<Scalars['String']>;
  currentAssets?: Maybe<Scalars['Float']>;
  intangibleAsset?: Maybe<Scalars['Float']>;
  fixedAssets?: Maybe<Scalars['Float']>;
  totalAssets?: Maybe<Scalars['Float']>;
  currentLiabilities?: Maybe<Scalars['Float']>;
  longTermDirectLiabilities?: Maybe<Scalars['Float']>;
  totalLiabilities?: Maybe<Scalars['Float']>;
  capital?: Maybe<Scalars['Float']>;
  earnedSurplus?: Maybe<Scalars['Float']>;
  grossProfit?: Maybe<Scalars['Float']>;
  netProfit?: Maybe<Scalars['Float']>;
  workingCapital?: Maybe<Scalars['Float']>;
  debtNetWorthRatio?: Maybe<Scalars['Float']>;
  currentAssetsLiabilitiesRatio?: Maybe<Scalars['Float']>;
  bankruptcyDate?: Maybe<Scalars['String']>;
  priorRepossesion?: Maybe<Scalars['String']>;
  repossesionDate?: Maybe<Scalars['String']>;
  creditorOneName?: Maybe<Scalars['String']>;
  creditorOneBalance?: Maybe<Scalars['String']>;
  creditorOneMonthlyPayment?: Maybe<Scalars['Float']>;
  creditorTwoName?: Maybe<Scalars['String']>;
  creditorTwoBalance?: Maybe<Scalars['String']>;
  creditorTwoMonthlyPayment?: Maybe<Scalars['Float']>;
  companyFinancingAutoLoan?: Maybe<Scalars['String']>;
  previousAutoLoanAccountNumber?: Maybe<Scalars['Float']>;
  previousAutoLoanBalance?: Maybe<Scalars['Float']>;
  previousAutoLoanMonthlyPayment?: Maybe<Scalars['Float']>;
  timeAtAddressInMonths?: Maybe<Scalars['Int']>;
  timeAtAddressInYears?: Maybe<Scalars['Int']>;
  routeOne?: Maybe<Scalars['Boolean']>;
  middleName?: Maybe<Scalars['String']>;
  residenceType?: Maybe<Scalars['String']>;
  monthlyMortgageOrRent?: Maybe<Scalars['Float']>;
  educationLevel?: Maybe<Scalars['String']>;
  employmentStatus?: Maybe<Scalars['String']>;
  incomeSourceOther?: Maybe<Scalars['String']>;
  incomeAmountPerYearOther?: Maybe<Scalars['Float']>;
  previousEmployerName?: Maybe<Scalars['String']>;
  previousEmployerPeriodOfEmploymentYears?: Maybe<Scalars['Int']>;
  previousEmployerPeriodOfEmploymentMonths?: Maybe<Scalars['Int']>;
  nameSuffix?: Maybe<Scalars['String']>;
  homeCounty?: Maybe<Scalars['String']>;
  employerName?: Maybe<Scalars['String']>;
  employerEmail?: Maybe<Scalars['String']>;
  employerPhone?: Maybe<Scalars['String']>;
  employerAddress?: Maybe<Scalars['String']>;
  employerAddress2?: Maybe<Scalars['String']>;
  employerCity?: Maybe<Scalars['String']>;
  employerState?: Maybe<Scalars['String']>;
  employerZip?: Maybe<Scalars['String']>;
  periodOfEmploymentYears?: Maybe<Scalars['Int']>;
  periodOfEmploymentMonths?: Maybe<Scalars['Int']>;
  equipment?: Maybe<EquipmentInput>;
  tradeIn?: Maybe<TradeInInput>;
  routeOneIndustryType?: Maybe<Scalars['String']>;
  ocaLanguage?: Maybe<Scalars['String']>;
  employeeTitle?: Maybe<Scalars['String']>;
  employeeType?: Maybe<Scalars['String']>;
  prevAddress?: Maybe<Scalars['String']>;
  prevAddressLine2?: Maybe<Scalars['String']>;
  prevPostalCode?: Maybe<Scalars['String']>;
  prevCity?: Maybe<Scalars['String']>;
  prevState?: Maybe<Scalars['String']>;
  prevCounty?: Maybe<Scalars['String']>;
  timeAtPrevAddressYears?: Maybe<Scalars['String']>;
  timeAtPrevAddressMonths?: Maybe<Scalars['String']>;
  ocaEnv?: Maybe<Scalars['Int']>;
  applicantMaritalStatus?: Maybe<Scalars['String']>;
  applicantSpousesLastName?: Maybe<Scalars['String']>;
  applicantSpousesFirstName?: Maybe<Scalars['String']>;
  applicantSpousesAddress?: Maybe<Scalars['String']>;
  applicantSpousesCity?: Maybe<Scalars['String']>;
  applicantSpousesState?: Maybe<Scalars['String']>;
  applicantSpousesZip?: Maybe<Scalars['String']>;
  docsUpload?: Maybe<Array<Maybe<OcaDocsInput>>>;
  addendum?: Maybe<Scalars['String']>;
  isOwnerPc?: Maybe<Scalars['Boolean']>;
  partnerId?: Maybe<Scalars['String']>;
  partnerDealerId?: Maybe<Scalars['String']>;
  nearestRelativeFirstName?: Maybe<Scalars['String']>;
  nearestRelativeLastName?: Maybe<Scalars['String']>;
  nearestRelativeCity?: Maybe<Scalars['String']>;
  nearestRelativePhone?: Maybe<Scalars['String']>;
  nearestRelativeState?: Maybe<Scalars['String']>;
  nearestRelativeZip?: Maybe<Scalars['String']>;
  termRequested?: Maybe<Scalars['Int']>;
  agriculturalExempt?: Maybe<Scalars['Boolean']>;
  downPaymentAmount?: Maybe<Scalars['Float']>;
  locationId?: Maybe<Scalars['ID']>;
  ocaLastPageVisited?: Maybe<Scalars['Int']>;
  ocaTotalPages?: Maybe<Scalars['Int']>;
  ocaLastPageNameVisited?: Maybe<Scalars['String']>;
  equipmentCategoryCode?: Maybe<Scalars['String']>;
  truckType?: Maybe<TruckType>;
  purchaseReason?: Maybe<Scalars['String']>;
  piecesOfHeavyEqpt?: Maybe<Scalars['Int']>;
  piecesOfMediumEqpt?: Maybe<Scalars['Int']>;
  piecesOfTrailerEqpt?: Maybe<Scalars['Int']>;
  totalEqpt?: Maybe<Scalars['Int']>;
  eqptUse?: Maybe<Scalars['String']>;
  numOfOwnerOps?: Maybe<Scalars['Int']>;
  typesOfGoods?: Maybe<Scalars['String']>;
  longHaulRegional?: Maybe<Scalars['String']>;
  hazmat?: Maybe<Scalars['Boolean']>;
  yearMakeModel?: Maybe<Scalars['String']>;
  payment?: Maybe<Scalars['Float']>;
  eqptPaidOff?: Maybe<Scalars['Boolean']>;
  eqptTrade?: Maybe<Scalars['Boolean']>;
  ownAuth?: Maybe<Scalars['Boolean']>;
  mcNumber?: Maybe<Scalars['String']>;
  usDotNum?: Maybe<Scalars['String']>;
  references?: Maybe<Array<Maybe<ReferencesInput>>>;
  eqptAddOrReplace?: Maybe<Scalars['String']>;
  applyingFor?: Maybe<Array<Maybe<Scalars["String"]>>>;
  invoiceDelivery?: Maybe<Scalars['String']>;
  purchaseOrder?: Maybe<Scalars['String']>;
  primaryAgriculturalProduct?: Maybe<Scalars['String']>;
  farmingStartYear?: Maybe<Scalars['Int']>;
  farmType?: Maybe<Scalars['String']>;
  farmingIncome?: Maybe<Scalars['Float']>;
  nonFarmingIncome?: Maybe<Scalars['Float']>;
  usCitizen?: 'US_CITIZEN' | 'FOREIGN_NATIONAL_WITH_E2_VISA' | 'US_FORMED_LEGAL_ENTITY';
  timeAtJobYears?: Maybe<Scalars['Int']>;
  timeAtJobMonths?: Maybe<Scalars['Int']>;

  fleetSegments?: Maybe<FleetSegments>;
  fleetOperation?: Maybe<FleetOperation>;
  seasonalBusiness?: Maybe<Scalars['Boolean']>;
  inactiveMonths?: Maybe<Months>;
  annualMileage?: Maybe<Scalars['Int']>;
  operatingLine?: Maybe<OperatingLine>;
  piecesOfTractorEqpt?: Maybe<Scalars['Int']>;
  piecesOfTruckEqpt?: Maybe<Scalars['Int']>;
  piecesOfUnitsPurchased?: Maybe<Scalars['Int']>;
  numberOfAdditions?: Maybe<Scalars['Int']>;
  numberOfReplacements?: Maybe<Scalars['Int']>;
  farmingStatus?: Maybe<FarmingStatus>;
  kubotaPrincipalUse?: Maybe<Scalars['String']>;
  useByApplicant?: Maybe<Scalars['Boolean']>;
  rawValues?: Maybe<Scalars['String']>;
  disclosure?: Maybe<Scalars['String']>;
  applicantType?: Maybe<ApplicantTypes>;
  hadItemRepossessed?: Maybe<Scalars['Boolean']>;
  repossessionDetail?: Maybe<Scalars['String']>;
  rentalHouse?: Maybe<Scalars['Boolean']>;
  firstTimeBuyer?: Maybe<Scalars['Boolean']>;
  insuranceWaiverContent?: Maybe<Scalars['String']>;
  addendumContent?: Maybe<Scalars['String']>;
  providingInsurance?: Maybe<ProvidingInsurance>;
  purchaseTimeframe?: Maybe<Scalars['String']>;
  authorizedParties?: Maybe<Scalars['String']>;
  workingCapitalRequestedAmount?: Maybe<Scalars['Float']>;
  averageDailyBalance?: Maybe<Scalars['Float']>;
  workingCapitalOffer?: Maybe<Scalars['String']>;
  equipments?: Maybe<Array<Maybe<EquipmentsInput>>>;
  sendCreditApplicationSignaturesTasks?: Maybe<Array<Scalars['String']>>;
  sendPersonalGuaranteeSignaturesTasks?: Maybe<Array<Scalars['String']>>;
  sendDocumentsSignaturesTasks?: Maybe<Scalars['JSON']>;
  source?: Maybe<Scalars['String']>;
  personalGuaranteeSignature?: Maybe<Scalars['String']>;
  attemptToSubmit?: Maybe<Scalars['Boolean']>;
};

export type TradeInInput = {
  year?: Maybe<Scalars['String']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  tradeInBodyStyle?: Maybe<Scalars['String']>;
  options?: Maybe<Scalars['String']>;
  wholesaleValueAmount?: Maybe<Scalars['Float']>;
};

export type EquipmentInput = {
  equipmentId?: Maybe<Scalars['ID']>;
  vendorOpportunityId?: Maybe<Scalars['ID']>;
  quantity?: Maybe<Scalars['Float']>;
  newUsed?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  serialNumberVin?: Maybe<Scalars['String']>;
  additionalDescription?: Maybe<Scalars['String']>;
  stockId?: Maybe<Scalars['String']>;
  operatorLastName?: Maybe<Scalars['String']>;
  operatorFirstName?: Maybe<Scalars['String']>;
  operatorAddress?: Maybe<Scalars['String']>;
  operatorZip?: Maybe<Scalars['String']>;
  operatorCity?: Maybe<Scalars['String']>;
  operatorState?: Maybe<Scalars['String']>;
  operatorMiddleName?: Maybe<Scalars['String']>;
  operatorAddress2?: Maybe<Scalars['String']>;
  operatorRelationship?: Maybe<Scalars['String']>;
  equipmentSpecialPrograms?: Maybe<Scalars['String']>;
  equipmentOfferingType?: Maybe<Scalars['String']>;
  salesOrderNumber?: Maybe<Scalars['String']>;
  applicationLanguage?: Maybe<Scalars['String']>;
  equipmentPreapproval?: Maybe<Scalars['Boolean']>;
  equipmentPreapprovalProgramName?: Maybe<Scalars['String']>;
  numberOfEngineCylinders?: Maybe<Scalars['Int']>;
  equipmentOptions?: Maybe<Scalars['String']>;
  makeOther?: Maybe<Scalars['String']>;
  modelOther?: Maybe<Scalars['String']>;
  style?: Maybe<Scalars['String']>;
  styleOther?: Maybe<Scalars['String']>;
  mileage?: Maybe<Scalars['Int']>;
  equipmentIntendedUse?: Maybe<Scalars['String']>;
  equipmentAssetType?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['Int']>;
  equipmentPictureUrl?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
};

export type OcaDocsInput = {
  docsUpload?: Maybe<Scalars['String']>;
  otherFileTypeName?: Maybe<Scalars['String']>;
  fileToUpload?: Maybe<Scalars['JSON']>;
};

export enum TruckType {
  Heavy = 'HEAVY',
  LightMed = 'LIGHT_MED',
  Fleet = 'FLEET',
}

export enum FarmingStatus {
  FullTime = 'FULL_TIME',
  PartTime = 'PART_TIME',
}

export enum ResidentStatus {
  USCitizen = 'US_CITIZEN',
  CanadianCitizen = 'CANADIAN_CITIZEN',
  ForeignNational = 'FOREIGN_NATIONAL',
  Other = 'OTHER',
}

export enum MaritalStatus {
  Married = 'MARRIED',
  Unmarried = 'UNMARRIED_SINGLE_WIDOWED_DIVORCED',
  Separated = 'SEPARATED',
}

export enum SectionTypes {
  CoApplicant = 'co-applicant',
  DocumentSign = 'document-sign',
  DynamicReference = 'dynamic-reference',
  DynamicSignatures = 'dynamic-signatures',
  Equipment = 'equipment',
  PersonalGuaranteeSignatures = 'personal-guarantee-signatures',
}

export enum SectionEquipmentTypes {
  Purchase = 'PURCHASE',
  TradeIn = 'TRADE_IN',
  ViewOnly = 'VIEW_ONLY'
}

export enum UserProfileStatusType {
  Active = 'active',
  PendingApproval = 'pending_approval',
  Approved = 'approved',
  Blocked = 'blocked',
}

export enum LenderProfleOcaBrandingEnum {
  Lender = "LENDER",
  Dealer = "DEALER",
  CoBranded = "CO_BRANDED",
}

export enum LenderProfleOcaTemplateEnum {
  Lender = "LENDER",
  Dealer = "DEALER",
}

export type ReferencesInput = {
  companyName?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  contactPhone?: Maybe<Scalars['String']>;
  contactFax?: Maybe<Scalars['String']>;
  accountNum?: Maybe<Scalars['String']>;
  originalLoanAmt?: Maybe<Scalars['Float']>;
  percRevenue?: Maybe<Scalars['Float']>;
  years?: Maybe<Scalars['Int']>;
  months?: Maybe<Scalars['Int']>;
  lineOfCredit?: Maybe<Scalars['Boolean']>;
  creditLimit?: Maybe<Scalars['Float']>;
  currentBalance?: Maybe<Scalars['Float']>;
  monthlyPayment?: Maybe<Scalars['Float']>;
  renewalDate?: Maybe<Scalars['String']>;
  referenceType?: 'CUSTOMER' | 'BANK' | 'LOAN' | 'LOC' | 'EMPLOYMENT' | 'TRADE' | 'TRADE_TWO' | 'TRADE_THREE';
  contactEmail?: Maybe<Scalars['String']>;
};

export type EquipmentsInput = {
  quantity?: Maybe<Scalars['Int']>;
  newUsed?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  mileage?: Maybe<Scalars['Int']>;
  hours?: Maybe<Scalars['String']>;
  serialNumberVin?: Maybe<Scalars['String']>;
  stockId?: Maybe<Scalars['String']>;
  retailPrice?: Maybe<Scalars['Float']>;
  tradeInValue?: Maybe<Scalars['Float']>;
  amountOwedOnTradeIn?: Maybe<Scalars['Float']>;
  additionalDescription?: Maybe<Scalars['String']>;
};

export type CreateCreditAppResponse = {
  success: Maybe<Scalars['Boolean']>;
  errorMessage: Maybe<Scalars['String']>;
  id: Maybe<Scalars['ID']>;
  dynamicsId: Maybe<Scalars['ID']>;
};

export interface QueryCreditApplicationPublic {
  creditApplicationPublic: CreditApplication
};
