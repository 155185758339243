import { FormikProps, useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { FieldConfigYearsSettingsType, FieldOptionsType, FieldType, SectionType } from "../components/types";
import { checkIfIsCountryField, checkIfIsStateField } from "../helpers";
import { Constants } from "../constants";
import _ from "lodash";

export default function useAutocomplete(field: FieldType, section: SectionType) {

  const formik: FormikProps<any> = useFormikContext();

  const [fieldOptions, setFieldOptions] = useState(field.config.fieldOptions || []);

  const handleSetFieldOptionsBasedOnYearsSettings = (yearsSettings: FieldConfigYearsSettingsType) => {
    let maxYear = yearsSettings.maxYear;
    if (maxYear === "") { // current year
      maxYear = yearsSettings.yearsInAdvance
        ? new Date().getFullYear() + yearsSettings.yearsInAdvance
        : new Date().getFullYear();
    }
    const minYear = yearsSettings.minYear || new Date().getFullYear();
    const yearsRange = _.range(maxYear, minYear - 1);
    setFieldOptions(_.map(yearsRange, (year: number): FieldOptionsType => {
      return {
        optionLabel: _.toString(year),
        optionValue: year,
        displayLabel: {
          en: _.toString(year),
          es: _.toString(year)
        }
      };
    }));
  };

  const handleSetFieldOptionsForStateField = () => {
    const nearestCountryField = section?.fields.find((f: FieldType) => checkIfIsCountryField(f.config.fieldName));
    if (!nearestCountryField) {
      return;
    }

    let nearestCountryFieldName = nearestCountryField.config.fieldName;
    const isComplexKeyUsed = _.includes(field.config.fieldName, ".");
    if (isComplexKeyUsed) {
      const prefix = field.config.fieldName.slice(0, field.config.fieldName.lastIndexOf(".") + 1);
      nearestCountryFieldName = `${prefix}${nearestCountryFieldName}`;
    }

    let optionsBySelectedCountry;
    switch (_.get(formik.values, nearestCountryFieldName)) {
      case "US":
        optionsBySelectedCountry = field.config.fieldOptions?.filter((fo: FieldOptionsType) => !Constants.canadianStates.includes(fo.optionValue as string))
        break;
      case "CA":
        optionsBySelectedCountry = field.config.fieldOptions?.filter((fo: FieldOptionsType) => Constants.canadianStates.includes(fo.optionValue as string))
        break;
      default:
        optionsBySelectedCountry = field.config.fieldOptions;
    }

    setFieldOptions(optionsBySelectedCountry || []);
  };

  useEffect(() => {
    const { yearsSettings } = field.config;
    if (_.isEmpty(fieldOptions) && !!yearsSettings) {
      handleSetFieldOptionsBasedOnYearsSettings(yearsSettings);
    }
  }, []);

  useEffect(() => {
    if (!checkIfIsStateField(field.config.fieldName)) {
      return;
    }

    handleSetFieldOptionsForStateField();
  }, [formik.values]);

  return {
    fieldOptions
  };
}