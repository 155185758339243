import { Grid } from '@mui/material';
import { OCAv4Section } from './OCAv4Section';
import { RuleEvaluatorWrapper } from './RuleEvaluationWrapper';
import { SectionType } from './types';
import _ from 'lodash';

export const OCAv4Page = ({
  config,
  template,
  externalData,
  vendorContactId,
  locationId,
  setRepData,
  setRepList,
}: {
  config: any;
  template: any;
  externalData?: any;
  vendorContactId?: string;
  locationId?: string;
  setRepData?: any;
  setRepList?: any;
}) => {
  const isPageVisible = _.get(config, 'config.displayed');
  if (!isPageVisible) {
    return null;
  }

  return (
    <Grid>
      {config.sections.map((section: SectionType, index: number) => {
        return (
          <RuleEvaluatorWrapper
            rules={section.rules}
            elementType={'section'}
            key={section.title.id}
            rulesSatisfactionType={section.rulesSatisfactionType}
            children={
              <OCAv4Section
                key={section.title.id}
                locationId={locationId}
                vendorContactId={vendorContactId}
                setRepData={setRepData}
                setRepList={setRepList}
                config={section}
                template={template}
                externalData={externalData}
              />
            }
          />
        );
      })}
    </Grid>
  );
};
