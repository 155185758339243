import { gql } from 'apollo-boost';

export const QUERY_PUBLIC_ACCOUNT_BY_ID = gql`
  query PublicAccountById($id: ID) {
    publicAccount(id: $id) {
      id
      name
      dynamicsAccountId
      address
      vendorProfile {
        id
        paymentSelectorAutomatic
        industryGroupType
        logo
        styles
        ocaForm
        altLanguageOca
        dcrDisclosureLanguage
        dcrDisclosureTerms
        dcrMarketingDisclosure
        dcrDisclosureLanguageSpanish
        dcrDisclosureTermsSpanish
        dcrDisclosureUseDefaultTerms
        ocaPersonalGuaranteeAgreementDisclosure
        amountRequested
        multiLocationManagement
        ocaTemplate {
          ocaTemplateId
          name
          jsonDefinition
          active
          jsonSettings
        }
      }
      primaryContact {
        fullName
        phoneNumber
        email
        mugshot
        availability
      }
      salesReps {
        fullName
        dynamicsContactId
        userProfileId
        phoneNumber
        email
        availability
        mugshot
        title
        status
      }
      locations {
        locationId
        locationName
        category
        locationParentId
        logo
        colorSchema
        ocaShowOnlyChildLocations
        ocaHidden
        ocaContact {
          id
          dynamicsContactId
          email
          firstName
          lastName
          fullName
          title
          phoneNumber
          #vendorContactRole
          mugshot
          availability
          #accountId
          #id
          #lastLogin
          #invitationCode
          #invitationDatetime
          #status
        }
        contactsInfo {
          email
          fullName
          title
          phoneNumber
          vendorContactRole
          mugshot
          availability
          dynamicsContactId
        }
      }
    }
  }
`;

export const QUERY_PUBLIC_ACCOUNT_BY_DYNAMICS_ID = gql`
  query PublicAccountByDynamicsId($dynamicsId: ID) {
    publicAccount(dynamicsId: $dynamicsId) {
      id
      name
      dynamicsAccountId
      address
      vendorProfile {
        id
        paymentSelectorAutomatic
        industryGroupType
        logo
        styles
        ocaForm
        altLanguageOca
        dcrDisclosureLanguage
        dcrDisclosureTerms
        dcrMarketingDisclosure
        dcrDisclosureLanguageSpanish
        dcrDisclosureTermsSpanish
        dcrDisclosureUseDefaultTerms
        ocaPersonalGuaranteeAgreementDisclosure
        amountRequested
        multiLocationManagement
        ocaTemplate {
          ocaTemplateId
          name
          jsonDefinition
          active
          jsonSettings
        }
      }
      primaryContact {
        fullName
        phoneNumber
        email
        mugshot
        availability
      }
      salesReps {
        fullName
        dynamicsContactId
        userProfileId
        phoneNumber
        email
        availability
        mugshot
        title
        status
      }
      locations {
        locationId
        locationName
        category
        locationParentId
        logo
        colorSchema
        ocaShowOnlyChildLocations
        ocaHidden
        ocaContact {
          id
          dynamicsContactId
          email
          firstName
          lastName
          fullName
          title
          phoneNumber
          #vendorContactRole
          mugshot
          availability
          #accountId
          #id
          #lastLogin
          #invitationCode
          #invitationDatetime
          #status
        }
        contactsInfo {
          email
          fullName
          title
          phoneNumber
          vendorContactRole
          mugshot
          availability
          dynamicsContactId
        }
      }
    }
  }
`;
