import { APP_ENV } from "./config";

import { useLazyQuery } from '@apollo/react-hooks';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { createTheme, Theme, ThemeProvider } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Route, Routes, useSearchParams, useNavigate, useLocation } from 'react-router-dom';
import { validate, v4 as uuidv4 } from 'uuid';
import {
  QUERY_CONTACT,
  QUERY_PUBLIC_ACCOUNT_BY_DYNAMICS_ID,
  QUERY_PUBLIC_ACCOUNT_BY_ID,
  QUERY_PARTNER,
  QUERY_PARTNER_LINK,
  QUERY_LENDER_PROFILE_BY_ID,
  QUERY_ACCOUNT_TASKS_TEMPLATES,
} from './api';
import { useWindowDimensions } from './helpers/customHooks';
import { Constants } from "./constants";
import checkFormIdStatus from './api/checkFormIdStatus';
import { LanguageProvider, VendorContext, CommonDataContext } from './context';
import CreditApplicationFormWrapper from './CreditApplicationFormWrapper';
import { getContrastColor, getURLParameter } from './helpers';
import MainLayout from './layouts';
import PageNotFound from './PageNotFound';
import { ContactPage } from './components/ContactPage/ContactPage';
import { TaskPage } from './components/TaskPages/TaskPage/TaskPage';
import mixpanel from "./mixpanel";
import { LenderProfileType, LenderProfleOcaBrandingEnum, LenderProfleOcaTemplateEnum, UserProfileStatusType } from "./api/types";
import { Task } from "@trnsact/trnsact-shared-types";
import { ReceiptPage } from "./components/ReceiptPage/ReceiptPage";
import { PaymentSelector, PSAccepted, PostError, PSFail, LenderPSConfirmed } from "./components/PaymentSelector/";


const useStyles = makeStyles((theme: Theme) => {
  return {
    hideMainContent: {
      opacity: 0,
    },
    backdrop: {
      zIndex: 100,
      color: '#fff',
    },
  };
});

function App() {

  const LEGACY_CREDIT_APP_ROUTES = ['/oca', '/credit-app'];

  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const defaultColorSchema = {
    color: '#1473e6',
    secColor: '#1473e6',
    textColor: '#000000',
    panelTextColor: '#ffffff',
  };


  //Main Data
  const [publicAccount, setPublicAccount] = useState({});
  const [vendorObj, setVendorObj] = useState({});
  const [partner, setPartner] = useState();
  const [partnerLink, setPartnerLink] = useState();
  const [lenderProfile, setLenderProfile] = useState<LenderProfileType>();
  const [lenderPublicAccount, setLenderPublicAccount] = useState<any>();
  const [dealerPublicAccount, setDealerPublicAccount] = useState<any>();
  //Main URL Arguments
  const [vendorGUID, setVendorGUID] = useState(getURLParameter('vendorGUID'));
  const [locationId, setLocationId] = useState(getURLParameter('locationId'));
  const [vendorContactId, setVendorContactId] = useState(
    getURLParameter('vendorcontactId')
  );

  // External DMS/CRM Deal / Opportunity ID
  const [extDmsOpportunityId, setExtDmsOpportunityId] = useState(getURLParameter('extDmsOpportunityId'));

  const [formId, setFormId] = useState(getURLParameter('formId'));
  const [loadingScreen, setLoadingScreen] = useState(true);
  const [repData, setRepData] = useState({
    mobilePhone: '',
    email: '',
    mugshot: 'avatar-placeholder.png',
    name: '',
    officePhone: '',
    availability: '',
  });

  const partnerId = getURLParameter('partnerId');
  const partnerDealerId = getURLParameter('partnerDealerId');
  const lenderProfileId = getURLParameter('lenderProfileId');
  const isLenderDealerOCA = !!lenderProfileId;
  const isTaskPage = window.location.href.includes('task-page');
  const { screenWidth } = useWindowDimensions();
  const isMobileView = screenWidth < Constants.MobileViewBreakpoint;
  const [highlightErrorFieldsState, setHighlightErrorFieldsState] = useState({ state: false });
  const [containerMaxWidth, setContainerMaxWidth] = useState(Constants.DefaultContainerMaxWidth);
  const [activeTemplate, setActiveTemplate] = useState();
  const [isLenderDealerCoBranded, setIsLenderDealerCoBranded] = useState(false);
  const [coBrandingSettings, setCoBrandingSettings] = useState<any>();
  const [accountTasksTemplates, setAccountTasksTemplates] = useState<Task[]>();
  const [task, setTask] = useState<Task>();
  const [contactByVendorContactIdQueryParam, setContactByVendorContactIdQueryParam] = useState();

  const [queryPublicAccountById, { data: publicDataResponseById }] =
    useLazyQuery(QUERY_PUBLIC_ACCOUNT_BY_ID);

  const [
    queryContactByVendorContactIdQueryParam,
    { data: contactByVendorContactIdData },
  ] = useLazyQuery(QUERY_CONTACT);

  const [
    queryPublicAccountByDynamicsId,
    { data: publicDataResponseByDynamicsId },
  ] = useLazyQuery(QUERY_PUBLIC_ACCOUNT_BY_DYNAMICS_ID);

  const [
    queryLenderPublicAccountByDynamicsId,
    { data: lenderPublicAccountResponse },
  ] = useLazyQuery(QUERY_PUBLIC_ACCOUNT_BY_DYNAMICS_ID);

  const [
    queryDealerPublicAccountByDynamicsId,
    { data: dealerPublicAccountResponse },
  ] = useLazyQuery(QUERY_PUBLIC_ACCOUNT_BY_DYNAMICS_ID);

  const [
    queryPartner,
    { data: partnerDataResponse },
  ] = useLazyQuery(QUERY_PARTNER);

  const [
    queryPartnerLink,
    { data: partnerLinkDataResponse },
  ] = useLazyQuery(QUERY_PARTNER_LINK);

  const [
    queryLenderProfile,
    { data: lenderProfileDataResponse },
  ] = useLazyQuery(QUERY_LENDER_PROFILE_BY_ID);

  const [
    queryAccountTasksTemplates,
    { data: accountTasksTemplatesData },
  ] = useLazyQuery(QUERY_ACCOUNT_TASKS_TEMPLATES);

  const handleFormId = async () => {

    const setNewFormId = () => {
      const newFormId = uuidv4();
      setFormId(newFormId);
      searchParams.set('formId', newFormId);
      setSearchParams(searchParams);

      /*       window.history.replaceState(
              null,
              'Online Credit App',
              `${process.env.PUBLIC_URL}?${searchParams.toString()}`
            ); */
    };

    const checkFormIdStatusCall = async () => {
      try {
        const { data } = await checkFormIdStatus(formId);
        return !!_.get(data, "data.checkFormIdStatus.used");
      } catch (e) {
        console.log('Failed to reload OCA');
      }
    };

    if (isTaskPage) { // no need to set formId for task page
      return;
    }

    if (!formId || !validate(formId)) {
      setNewFormId();
      return;
    }

    const isFormIdAlreadyUsed = await checkFormIdStatusCall();
    if (isFormIdAlreadyUsed) {
      setNewFormId();
    }
  };

  useEffect(() => {
    const handleRedirectFromLegacyRoute = () => {
      if (location.pathname !== '/' && LEGACY_CREDIT_APP_ROUTES.includes(_.trimEnd(location.pathname, '/'))) {
        navigate({
          pathname: '/',
          search: searchParams.toString()
        });
      }
    };

    handleFormId()
      .then(handleRedirectFromLegacyRoute);
  }, [formId]);

  window.addEventListener("popstate", () => {
    window.location.reload(); // hotfix - to reset formId if needed when user click back button from receipt page
  });

  useEffect(() => {
    const publicDataResponse = publicDataResponseById || publicDataResponseByDynamicsId;
    if (_.isEmpty(publicDataResponse)) {
      return;
    }
    handleOCAValuesSettingByPublicAccount(publicDataResponse.publicAccount);

    // commented since not in use anymore
    /*       queryAccountTasksTemplates(
            {
              variables: {
                options: {
                  assignedToAccountId: publicDataResponse.publicAccount.id,
                  isTemplate: true,
                },
              }
            }); */
  }, [publicDataResponseByDynamicsId, publicDataResponseById]);

  useEffect(() => {
    if (_.isEmpty(accountTasksTemplatesData)) {
      return;
    }
    setAccountTasksTemplates(accountTasksTemplatesData.tasks);
  }, [accountTasksTemplatesData]);

  useEffect(() => {
    if (vendorGUID) {
      queryPublicAccountByDynamicsId({
        variables: { dynamicsId: vendorGUID.toLowerCase() },
      });
    }
  }, [vendorGUID]);

  useEffect(() => {

    if (!vendorContactId) {
      return;
    }

    if (!contactByVendorContactIdData) {
      queryContactByVendorContactIdQueryParam({
        variables: { dynamicsId: vendorContactId.toLowerCase() },
      });
    }

    if (contactByVendorContactIdData) {
      const contact = _.get(contactByVendorContactIdData, 'contact', '');
      if (!contact || !contact.accountId) {
        //@ts-ignore
        window.top.location = `${process.env.PUBLIC_URL}/no-vendor`;
        return;
      }
      setContactByVendorContactIdQueryParam(contact);
      if (contact.locationId) {
        searchParams.set('locationId', contact.locationId);
        setSearchParams(searchParams);
        window.history.replaceState(
          null,
          'Online Credit App',
          `${process.env.PUBLIC_URL}?${searchParams.toString()}`
        );
      }
      if (!isLenderDealerOCA) {
        queryPublicAccountById({
          variables: { id: contact.accountId },
        });
      }
    }
  }, [vendorContactId, contactByVendorContactIdData]);

  useEffect(() => {
    if (partnerId) {
      queryPartner({ variables: { dynamicsId: partnerId } });
    }
    if (partnerDealerId) {
      queryPartnerLink({ variables: { partnerDealerId: partnerDealerId } });
    }
  }, [partnerId, partnerDealerId]);

  useEffect(() => {
    const partnerData = _.get(partnerDataResponse, "partner");
    const partnerLinkData = _.get(partnerLinkDataResponse, "partnerLink");
    if (_.isEmpty(partnerData) && _.isEmpty(partnerLinkData)) {
      return;
    }
    setPartner(partnerData);
    setPartnerLink(partnerLinkData);

    const dynamicsPartnerAccountId = _.get(partnerLinkData, "dynamicsPartnerAccountId") ||
      _.get(partnerData, "partnerAccountDynamicsId");
    if (dynamicsPartnerAccountId) {
      queryPublicAccountByDynamicsId({
        variables: { dynamicsId: dynamicsPartnerAccountId },
      });
    }
  }, [partnerDataResponse, partnerLinkDataResponse]);

  useEffect(() => {
    if (!lenderProfileId) {
      return;
    }
    queryLenderProfile({ variables: { lenderProfileId } });
  }, [lenderProfileId]);

  // LD OCA
  useEffect(() => {
    if (_.isEmpty(lenderProfileDataResponse)) {
      return;
    }
    setLenderProfile(lenderProfileDataResponse.lenderProfileById);
    setIsLenderDealerCoBranded(lenderProfileDataResponse.lenderProfileById.ocaBranding === LenderProfleOcaBrandingEnum.CoBranded);

    const { lenderDynamicsAccountId, dealerDynamicsAccountId } = lenderProfileDataResponse.lenderProfileById;
    queryLenderPublicAccountByDynamicsId({
      variables: { dynamicsId: lenderDynamicsAccountId },
    });

    queryDealerPublicAccountByDynamicsId({
      variables: { dynamicsId: dealerDynamicsAccountId },
    });

  }, [lenderProfileDataResponse]);

  // LD OCA
  useEffect(() => {
    if (_.isEmpty(lenderPublicAccountResponse) || _.isEmpty(dealerPublicAccountResponse)) {
      return;
    }
    setLenderPublicAccount(lenderPublicAccountResponse.publicAccount);
    setDealerPublicAccount(dealerPublicAccountResponse.publicAccount);
  }, [lenderPublicAccountResponse, dealerPublicAccountResponse]);

  useEffect(() => {
    if (_.isEmpty(lenderPublicAccount) || _.isEmpty(dealerPublicAccount)) {
      return;
    }
    handleOCAValuesSettingByPublicAccount();
  }, [lenderPublicAccount, dealerPublicAccount]);


  const handleOCAValuesSettingByPublicAccount = (
    publicAccount?: any,
  ) => {

    const getPublicAccountToUse = () => {
      if (isLenderDealerOCA) {
        return lenderProfile?.ocaTemplate === LenderProfleOcaTemplateEnum.Lender
          ? lenderPublicAccount
          : dealerPublicAccount;
      }
      return publicAccount;
    };

    const getColorSchemaToUse = (publicAccountToUse: any) => {
      let vpColorSchemaToUse;
      if (isLenderDealerOCA) {
        if (lenderProfile?.ocaBranding === LenderProfleOcaBrandingEnum.CoBranded) {
          // if co-brander, choose account based on ocaTemplate setting
          const accountToUse = lenderProfile?.ocaTemplate === LenderProfleOcaTemplateEnum.Lender
            ? lenderPublicAccount
            : dealerPublicAccount;
          vpColorSchemaToUse = _.get(accountToUse, 'vendorProfile.styles');
        } else {
          const accountToUse = lenderProfile?.ocaBranding === LenderProfleOcaBrandingEnum.Lender
            ? lenderPublicAccount
            : dealerPublicAccount;
          vpColorSchemaToUse = _.get(accountToUse, 'vendorProfile.styles');
        }
      } else {
        vpColorSchemaToUse = _.get(publicAccountToUse, 'vendorProfile.styles');
      }
      const currentLocation = _.find(publicAccountToUse.locations, { 'locationId': getURLParameter('locationId') });
      return _.get(partner, 'styles') ||
        _.get(currentLocation, 'colorSchema') ||
        vpColorSchemaToUse ||
        defaultColorSchema;
    };

    const getVPLogoToUse = (publicAccountToUse: any) => {
      if (isLenderDealerOCA) {
        if (lenderProfile?.ocaBranding === LenderProfleOcaBrandingEnum.CoBranded) {
          // if co-brander, choose account based on ocaTemplate setting
          const accountToUse = lenderProfile?.ocaTemplate === LenderProfleOcaTemplateEnum.Lender
            ? lenderPublicAccount
            : dealerPublicAccount;
          return _.get(accountToUse, 'vendorProfile.logo');
        } else {
          const accountToUse = lenderProfile?.ocaBranding === LenderProfleOcaBrandingEnum.Lender
            ? lenderPublicAccount
            : dealerPublicAccount;
          return _.get(accountToUse, 'vendorProfile.logo');
        }
      }
      return _.get(publicAccountToUse, 'vendorProfile.logo');
    };

    const getOcaTemplateToUse = (publicAccountToUse: any) => _.find(publicAccountToUse.vendorProfile.ocaTemplate, 'active');

    const getSalesRepsToUse = (publicAccountToUse: any) => _.reject(publicAccountToUse.salesReps, { status: UserProfileStatusType.Blocked });

    const getPrimaryContactToUse = (publicAccountToUse: any, salesRepsToUse: any) => {
      if (isLenderDealerOCA) {
        if (contactByVendorContactIdQueryParam && lenderProfile?.ocaTemplate === LenderProfleOcaTemplateEnum.Dealer) {
          return contactByVendorContactIdQueryParam;
        }
        const lenderProfilePrimaryContactUserId = lenderProfile?.ocaTemplate === LenderProfleOcaTemplateEnum.Lender
          ? lenderProfile?.ocaLenderUserProfileId
          : lenderProfile?.ocaDealerUserProfileId;
        return _.find(salesRepsToUse, { userProfileId: lenderProfilePrimaryContactUserId }) ||
          publicAccountToUse.primaryContact
      }
      return publicAccountToUse.primaryContact;
    };

    const getCoBrandingSettingsToUse = (colorSchemaToUse: any, vpLogoToUse: any) => {
      if (!isLenderDealerOCA || lenderProfile?.ocaBranding !== LenderProfleOcaBrandingEnum.CoBranded) {
        return null;
      }

      const getFormWrapperContact = (formWrapperAccountToUse: any) => {
        if (contactByVendorContactIdQueryParam && lenderProfile?.ocaTemplate === LenderProfleOcaTemplateEnum.Lender) {
          return contactByVendorContactIdQueryParam;
        }
        const lenderProfilePrimaryContactUserId = lenderProfile?.ocaTemplate === LenderProfleOcaTemplateEnum.Lender
          ? lenderProfile?.ocaDealerUserProfileId  // get ocaDealerUserProfileId if lender oca template is used
          : lenderProfile?.ocaLenderUserProfileId; // get ocaLenderUserProfileId if dealer oca template is used

        return _.find(formWrapperAccountToUse.salesReps, { userProfileId: lenderProfilePrimaryContactUserId }) ||
          formWrapperAccountToUse.primaryContact;
      };

      const formWrapperAccountToUse = lenderProfile?.ocaTemplate === LenderProfleOcaTemplateEnum.Lender
        ? dealerPublicAccount  // get formWrapper data from dealer if lender oca template is used
        : lenderPublicAccount; // get formWrapper data from lender if dealer oca template is used
      const formWrapperLogo = _.get(formWrapperAccountToUse, 'vendorProfile.logo');
      const formWrapperContact = getFormWrapperContact(formWrapperAccountToUse);
      const formWrapperHeaderText = lenderProfile?.ocaTemplate === LenderProfleOcaTemplateEnum.Lender
        ? 'Prepared exclusively for:'
        : 'In partnership with:';

      return {
        colorSchema: colorSchemaToUse,  // based on OCA template type (Dealer or Lender)
        headerLogo: vpLogoToUse,  // based on OCA template type (Dealer or Lender)
        formWrapperLogo,
        formWrapperHeaderText,
        formWrapperContact,
      };
    };

    const publicAccountToUse = getPublicAccountToUse();
    const accountName = _.get(publicAccountToUse, 'name');
    if (!accountName) {
      //@ts-ignore
      window.top.location = `${process.env.PUBLIC_URL}/no-vendor`;
    }

    const colorSchemaToUse = getColorSchemaToUse(publicAccountToUse);
    const vpLogoToUse = getVPLogoToUse(publicAccountToUse);
    const ocaTemplateToUse = getOcaTemplateToUse(publicAccountToUse);
    const salesRepsToUse = getSalesRepsToUse(publicAccountToUse);
    const primaryContactToUse = getPrimaryContactToUse(publicAccountToUse, salesRepsToUse);
    const coBrandingSettingsToUse = getCoBrandingSettingsToUse(colorSchemaToUse, vpLogoToUse);

    setVendorObj({
      ...publicAccountToUse.vendorProfile,
      partner,
      partnerLink,
      accountName,
      logo: vpLogoToUse,
      color: colorSchemaToUse.color || "#FFFFFF",
      secColor: colorSchemaToUse.secColor || colorSchemaToUse.color,
      textColor: colorSchemaToUse.textColor || 'rgba(0, 0, 0, 0.6)',
      panelTextColor: colorSchemaToUse.panelTextColor || getContrastColor(colorSchemaToUse.color || "#FFFFFF")
    });
    setPublicAccount({
      ...publicAccountToUse,
      salesReps: salesRepsToUse,
      primaryContact: primaryContactToUse,
    });
    setActiveTemplate(ocaTemplateToUse);
    setCoBrandingSettings(coBrandingSettingsToUse);
    setLoadingScreen(false);

    if (!!partnerId && !!partnerDealerId) {
      mixpanel.register({
        partnerId,
        dealerName: accountName,
        partnerDealerId,
        environment: APP_ENV,
      });
      mixpanel.track("OCA Rendered", { send_immediately: true });
    }
  };

  const setRepList = (newSalesRep: any) => {
    setPublicAccount({ ...publicAccount, salesReps: newSalesRep });
  };

  const theme = createTheme({
    palette: {
      primary: { main: _.get(vendorObj, 'color', '#1473e') },
    },
  });

  const classes = useStyles(theme);

  return (
    <>
      <Backdrop className={classes.backdrop} open={loadingScreen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <CommonDataContext.Provider value={{
        isMobileView,
        isLenderDealerCoBranded,
        coBrandingSettings,
        accountTasksTemplates,
        publicAccount,
        isLenderDealerOCA,
        highlightErrorFieldsState,
        setHighlightErrorFieldsState,
        containerMaxWidth,
        setContainerMaxWidth,
        task,
      }}>
        <VendorContext.Provider value={{ ...vendorObj }}>
          <ThemeProvider theme={theme}>
            <LanguageProvider vendorProfile={vendorObj} partnerId={partnerId}>
              <div className={`${loadingScreen ? classes.hideMainContent : ''}`}>
                <header>
                  <Routes>
                    <Route
                      path='/'
                      element={
                        <MainLayout
                          publicAccount={publicAccount}
                          repData={repData}
                          setRepData={setRepData}
                          partner={partner}
                          partnerLink={partnerLink}
                          containerMaxWidth={containerMaxWidth}
                        >
                          <CreditApplicationFormWrapper
                            vendorContactId={vendorContactId}
                            vendorGUID={vendorGUID}
                            locationId={locationId}
                            publicAccount={publicAccount}
                            repData={repData}
                            setRepData={setRepData}
                            formId={formId}
                            setRepList={setRepList}
                            activeTemplate={activeTemplate}
                            extDmsOpportunityId={extDmsOpportunityId}
                          />
                        </MainLayout>
                      }
                    >
                    </Route>
                    <Route
                      path={`contact`}
                      element={
                        <ContactPage
                          publicAccount={publicAccount}
                          vendorGUID={vendorGUID}
                          partner={partner}
                          partnerLink={partnerLink}
                        />
                      }
                    />
                    <Route
                      path={`task-page`}
                      element={
                        <MainLayout
                          publicAccount={publicAccount}
                          repData={repData}
                          setRepData={setRepData}
                          partner={partner}
                          partnerLink={partnerLink}
                          containerMaxWidth={containerMaxWidth}
                        >
                          <TaskPage
                            publicAccount={publicAccount}
                            activeTemplate={activeTemplate}
                            task={task}
                            setTask={setTask}
                          />
                        </MainLayout>

                      }
                    />
                    <Route
                      path={`receipt`}
                      element={
                        <MainLayout
                          publicAccount={publicAccount}
                          repData={repData}
                          setRepData={setRepData}
                          partner={partner}
                          partnerLink={partnerLink}
                          containerMaxWidth={containerMaxWidth}
                        >
                          <ReceiptPage
                            publicAccount={publicAccount}
                            activeTemplate={activeTemplate}
                          />
                        </MainLayout>
                      }
                    />
                    <Route path="ps"
                      element={
                        <MainLayout
                          publicAccount={publicAccount}
                          repData={repData}
                          setRepData={setRepData}
                          partner={partner}
                          partnerLink={partnerLink}
                          containerMaxWidth={containerMaxWidth}
                        >
                          <PaymentSelector setRepData={setRepData} setVendorObj={setVendorObj} />
                        </MainLayout>
                      } />

                    <Route path="lenderConfirmed"
                      element={<MainLayout
                        publicAccount={publicAccount}
                        repData={repData}
                        setRepData={setRepData}
                        partner={partner}
                        partnerLink={partnerLink}
                        containerMaxWidth={containerMaxWidth}
                      >
                        <LenderPSConfirmed />
                      </MainLayout>} />

                    <Route path="pserror"
                      element={
                        <MainLayout
                          publicAccount={publicAccount}
                          repData={repData}
                          setRepData={setRepData}
                          partner={partner}
                          partnerLink={partnerLink}
                          containerMaxWidth={containerMaxWidth}
                        ><PSFail />
                        </MainLayout>
                      }
                    />

                    <Route path="posterror" element={<MainLayout
                      publicAccount={publicAccount}
                      repData={repData}
                      setRepData={setRepData}
                      partner={partner}
                      partnerLink={partnerLink}
                      containerMaxWidth={containerMaxWidth}
                    >
                      <PostError />
                    </MainLayout>}
                    />


                    <Route path="psconfirmed" element={<MainLayout
                      publicAccount={publicAccount}
                      repData={repData}
                      setRepData={setRepData}
                      partner={partner}
                      partnerLink={partnerLink}
                      containerMaxWidth={containerMaxWidth}
                    >
                      <PSAccepted />
                    </MainLayout>} />

                    <Route path={`*`} element={<PageNotFound />} />
                  </Routes>
                </header>
              </div>
            </LanguageProvider>
          </ThemeProvider>
        </VendorContext.Provider>
      </CommonDataContext.Provider >
    </>
  );
}

export default App;

const vendorData = {
  MSBC: {
    logo: 'main-street.png',
    color: '#0071BD',
  },
  default: {
    color: '#1473e6',
    repdId: '2067558D-EE75-E011-94BF-005056A20000',
  },
};
