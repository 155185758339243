import React, { useContext, useEffect, useState } from 'react'
import { makeStyles } from "@mui/styles";
import { useFormikContext } from "formik";
import { Box, Checkbox, FormControlLabel } from "@mui/material";
import { FieldType } from "../types";
import { CommonDataContext } from "../../context";

export const MarketingDisclosuresField = ({ itemData, displayLabel }: {
  displayLabel: string;
  itemData: FieldType;
}) => {
  const classes = useStyles();

  const { highlightErrorFieldsState } = useContext(CommonDataContext);

  const [isHighlightError, setIsHighlightError] = useState<boolean>(false)

  const formik = useFormikContext<any>();

  const fieldName = `isMarketingApproved`
  const isFieldRequired = itemData.config?.required ?? false

  const handleChange = (checked: boolean) => {
    formik.setFieldValue(fieldName, checked);

    if (isFieldRequired) {
      setIsHighlightError(!checked)
      formik.setFieldError(fieldName, !checked ? 'required' : '');
    }
  }

  useEffect(() => {
    setIsHighlightError(!!formik.getFieldMeta(fieldName).error)
  }, [highlightErrorFieldsState])

  useEffect(() => {
    formik.setFieldValue(fieldName, false);
  }, []);

  useEffect(() => {
    if (isFieldRequired) formik.setFieldError(fieldName, 'required');

    return () => {
      formik.setFieldError(fieldName, '');
    };
  }, []);

  return (
    <Box className={classes.fieldContainer}>
      <FormControlLabel
        label={displayLabel}
        style={{ color: isHighlightError ? '#d32f2f' : 'inherit' }}
        control={
          <Checkbox
            id={itemData.id}
            name={fieldName}
            required={isFieldRequired}
            checked={formik.values[fieldName]}
            onChange={(_, checked) => {
              handleChange(checked);
            }}
          />
        }
      />
    </Box>
  )
}

const useStyles = makeStyles(({
  fieldContainer: {
    marginLeft: '2rem'
  }
}))

