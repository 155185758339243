import { Button, Grid } from '@mui/material';
import { FieldArray, FormikProps, useFormikContext } from 'formik';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Element } from '../Element';
import { FieldConfigType, FieldType, RuleType, SectionType } from '../types';

const Owners = ({
  config,
  locale,
  externalData,
}: {
  config: SectionType;
  locale: string;
  externalData: any;
}) => {
  const formik: FormikProps<any> = useFormikContext();
  const [enableAddOwners, setEnableAddOwners] = useState(true);

  const { isIndividualApp, ownersSectionParams } = externalData;

  useEffect(() => {
    const ownersQuantity = _.size(_.get(formik, 'values.owners', []));
    formik.setFieldValue('ownersQuantity', ownersQuantity, false);

    const totalPercentage = _.sum(
      _.get(formik, 'values.owners', []).map((onwerNode: any) =>
        parseInt(_.get(onwerNode, 'percOwner', '0') || '0')
      )
    );
    const hasOwnershipPercentage =
      _.size(
        _.filter(
          _.get(formik, 'values.owners', []).map((onwerNode: any) =>
            _.has(onwerNode, 'percOwner')
          ),
          (res: boolean) => res === true
        )
      ) >= 1;

    if (hasOwnershipPercentage) {
      formik.setFieldValue('totalPercentage', totalPercentage, false);
      setEnableAddOwners(totalPercentage < 100);
    } else {
      formik.setFieldValue('totalPercentage', 100, false);
    }
  }, [formik.values.owners]);

  const getOwnerBlockTitle = (ownerIndex: number) => {
    if (isIndividualApp) {
      const titleId = ownerIndex === 0 ? 'ownerApplicant' : 'ownerCoApplicant';
      return <FormattedMessage
        id={titleId}
        defaultMessage={titleId}
      />
    }
    return (
      <>
        <FormattedMessage
          id={'ownerOffier'}
          defaultMessage={'ownerOffier'}
        />
        {ownerIndex + 1}
      </>
    )
  };


  const isNeedToRenderRemoveButton = (ownerIndex: number) => {
    return !ownersSectionParams?.hideAddRemoveOwners &&
      _.get(formik, `values.owners.length`) > 1 &&
      ownerIndex >= 1;
  }

  return (
    <Grid container spacing={4}>
      <FieldArray name="owners">
        {({ remove, push }) => {
          return (
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Grid container spacing={2}>
                {(formik.values.owners || []).map(
                  (owner: any, ownerIndex: number) => (
                    <Grid
                      item
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      key={`ownersSection.${ownerIndex}`}
                    >
                      {!externalData?.ownersSectionParams?.hideTitle && <Grid item xs={12}>
                        <h3 style={{ fontWeight: 500, fontFamily: 'Source Sans Pro, sans-serif', fontSize: 18, textAlign: 'center' }}>
                          {getOwnerBlockTitle(ownerIndex)}
                        </h3>
                      </Grid>
                      }
                      <Grid container spacing={5}>
                        {config.fields.map((field: FieldType, i: number) => {
                          let rules: Array<RuleType> = [];

                          _.get(field, 'rules', []).map((rule: RuleType) => {
                            switch (rule.scope) {
                              //If a rule is local, it's meant to target a field that will be under the "owners" array.
                              //this means that the targetname prop needs to be changed to follow the "owners" array path
                              // e.g:
                              //BEFORE: homeCity
                              //AFTER: owners.[INDEX_VALUE].homeCity
                              case 'local':
                                rules.push({
                                  ...rule,
                                  targetName:
                                    `owners[${ownerIndex}].${rule.targetName}` as unknown as Pick<
                                      FieldConfigType,
                                      'fieldName'
                                    >,
                                });
                                return rule;
                              case 'global':
                                //if a rule is global, which is the default scope, we are not going to change anything
                                rules.push(rule);
                                return rule;
                            }
                          });

                          return (
                            <Element
                              item={{
                                ...field,
                                config: {
                                  ...field.config,
                                  fieldName: `owners[${ownerIndex}].${field.config.fieldName}`,
                                },
                                rules,
                              }}
                              locale={locale}
                              key={`owners${ownerIndex}.${i}.${field.config.fieldName}`}
                              externalData={externalData}
                            />
                          );
                        })}

                        {isNeedToRenderRemoveButton(ownerIndex) &&
                          <Grid item xs={12}>
                            <Button
                              variant="outlined"
                              onClick={() => {
                                remove(ownerIndex);
                              }}
                            >
                              <FormattedMessage
                                id={isIndividualApp ? 'removeCoApplicant' : 'removeOwner'}
                                defaultMessage={isIndividualApp ? 'removeCoApplicant' : 'removeOwner'}
                              />
                            </Button>
                          </Grid>
                        }
                      </Grid>
                    </Grid>
                  )
                )}

                {!ownersSectionParams?.hideAddRemoveOwners &&
                  <Grid item xs={12}>
                    <Button
                      variant="outlined"
                      disabled={!enableAddOwners}
                      onClick={() =>
                        push(
                          config.fields.reduce((acc, curr) => {
                            return {
                              ...acc,
                              [curr.config.fieldName]: null,
                            };
                          }, {})
                        )
                      }
                    >
                      <FormattedMessage
                        id={isIndividualApp ? 'addCoApplicant' : 'additionalOwners'}
                        defaultMessage={isIndividualApp ? 'addCoApplicant' : 'additionalOwners'}
                      />
                    </Button>
                  </Grid>
                }
              </Grid>
            </Grid>
          );
        }}
      </FieldArray>
    </Grid>
  );
};

export { Owners };
