import { Grid, Typography } from '@mui/material';
import { pascalCase } from 'change-case';
import { useContext } from 'react';
import { CommonDataContext } from '../context';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  AmountField,
  AutocompleteField,
  CustomCheckbox,
  CustomTextField,
  DateField,
  EmailField,
  ImageInput,
  PercentField,
  PhoneField,
  PostalcodeField,
  RadioQuestion,
  SelectField,
  SignField,
  SocialsecurityField,
  TaxidField,
  TypedField,
  DocumentQuestion,
  SignatureselectField,
  SignorAvailabilityQuestion,
  RepresentativeSelect,
  LocationSelect,
  MultiselectField,
  HtmlOutputField,
  TypedFileInput,
  SpacerField,
  DynamicSignatureAndTitle,
  HiddenField,
  IsOwnerOrOfficerField,
  WhoIsPresentField,
  MaskedValueField,
  MarketingDisclosuresField
} from './fields';
import { FieldType } from './types';

const fieldComponents = {
  AmountField,
  AutocompleteField,
  CustomCheckbox,
  CustomTextField,
  DateField,
  EmailField,
  ImageInput,
  PercentField,
  PhoneField,
  PostalcodeField,
  RadioQuestion,
  SelectField,
  SignField,
  SocialsecurityField,
  TaxidField,
  TypedField,
  DocumentQuestion,
  TextField: CustomTextField,
  SignatureselectField,
  SignorAvailabilityQuestion,
  RepresentativeSelect,
  LocationSelect,
  MultiselectField,
  HtmlOutputField,
  TypedFileInput,
  SpacerField,
  DynamicSignatureAndTitle,
  HiddenField,
  IsOwnerOrOfficerField,
  WhoIsPresentField,
  MaskedValueField,
  MarketingDisclosuresField
};

export const FieldComponentFactory = (
  item: FieldType,
  fieldValue: any,
  locale: string,
  isMobileView: boolean,
  externalData?: any,
  externalCallbacks?: any,
): React.FunctionComponentElement<any> | React.ComponentElement<any, any> => {
  const name = pascalCase(item.type);
  const componentRef: any = _.get(fieldComponents, [name], false);

  const displayLabel = _.get(item, `config.displayLabel.${locale}`, '');
  const displayLabelLength = _.size(displayLabel);
  const isNeedToMoveLabelOutOfInput = isMobileView && displayLabelLength > 35

  if (componentRef !== false) {
    return React.createElement(componentRef, {
      display: item.config.displayed,
      locale,
      value: fieldValue,
      itemData: item,
      externalData,
      externalCallbacks,
      displayLabel,
      isNeedToMoveLabelOutOfInput
    });
  }
  // component doesn't exist yet
  console.warn(`FACTORY: could not create ${name}`);
  return React.createElement(() => (
    <Grid container>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Typography
          variant="h6"
          color={'red'}
          fontSize={12}
          fontFamily={'monospace'}
        >
          The component named: {name} does not exists in the repository.
        </Typography>
      </Grid>
    </Grid>
  ));
};
