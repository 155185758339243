import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import sslLogo from './assets/images/ssl-logo-300x166.png';
import { OCAv4 } from './components/OCAv4';
import { VendorContext, CommonDataContext } from './context';
import defaultMessage from './context/Message';
import styles from './CreditApplicationFormWrapper.module.css';
import LogoFull from './assets/images/trnsact-logo-powered-by-light.png';
import { getURLParameter } from './helpers';

import './index.css';

const config = require('./config');

const stepLabels = [
  <FormattedMessage
    id="creditApplication"
    defaultMessage={defaultMessage.creditApplication}
  />,
  <FormattedMessage
    id="paymentSelection"
    defaultMessage={defaultMessage.paymentSelection}
  />,
  <FormattedMessage
    id="signYourDocs"
    defaultMessage={defaultMessage.signYourDocs}
  />,
];

const useStyles = makeStyles((theme: any) => ({
  stepperContainer: {
    margin: '0 -36px',
  },
  formContainer: {
    filter: (props: any) => (props.isOpenModal ? 'blur(10px)' : ''),
    pointerEvents: (props: any) => (props.isOpenModal ? 'none' : 'auto'),
  },
  needHelpButton: {
    borderRadius: '4px 4px 0 0',
    backgroundColor: '#9D9D9D !important',
    padding: '10px',
    minWidth: '120px',
    marginTop: 20,
  },
  closeModalButton: {
    backgroundColor: '#1473E6 !important',
    borderRadius: '4px 4px 0 0',
    padding: '10px',
    minWidth: '120px',
    marginTop: 20,
  },
  modalDialog: {
    height: '800px',
    width: 'calc(100% - 40px) !important',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    zIndex: '1200',
    minHeight: '560px',
    [theme.breakpoints.down('md')]: {
      paddingTop: '230px',
    },
  },
  chatWithDealer: {
    fontWeight: 500,
    fontSize: 16,
    cursor: 'pointer',
    color: '#3785F4',
  },
  h3: {
    fontSize: '40px',
    fontWeight: 500,
  },

  h4: {
    fontSize: '34px',
    fontWeight: 500,
  },

  h5: {
    fontSize: '20px',
    fontWeight: 500,
  },

  pGrey: {
    color: '#707070',
  },

  footerText: {
    fontSize: '12px',
  },
  prequalifyContainer: {
    display: 'flex',
    color: '#707070',
    paddingLeft: "20px",
    borderLeft: "2px solid #e5e5e5",
  },
  prequalifyContainerPartner: {
    borderLeft: "none",
    textAlign: "center",
  },
  prequalifyHeader: {
    fontSize: '20px',
    fontWeight: '500',
  },
  ldCoBrandedContainer: {
    marginBottom: '16px',
  },
  ldCoBrandedHeaderText: {
    textAlign: 'center',
    fontWeight: 'bold'
  },
  ldContactContainer: {
    display: 'flex',
    paddingLeft: '20px',
    borderLeft: '2px solid #e5e5e5',
    "& p": {
      margin: '8px 0',
    },
  },
  ldCoBrandedLogoWrapper: {
    paddingRight: '20px',
  },
}));

const CreditApplicationFormWrapper = ({
  publicAccount,
  repData,
  vendorGUID,
  vendorContactId,
  locationId,
  formId,
  setRepData,
  setRepList,
  activeTemplate,
  extDmsOpportunityId,
}: {
  publicAccount: any;
  repData: any;
  vendorGUID: string;
  vendorContactId: string;
  locationId: string;
  formId: string;
  setRepData: any;
  setRepList: any;
  activeTemplate: any;
  extDmsOpportunityId: string | undefined;
}) => {
  const [isOpenModal, setOpenModal] = useState(false);
  const {
    color: vendorColor,
    logo: vendorLogo,
    partner,
    partnerLink,
  } = useContext(VendorContext);
  const { isLenderDealerCoBranded, coBrandingSettings } = useContext(CommonDataContext);
  const classes = useStyles({ isOpenModal, vendorColor });

  const [ocaConfig, setOcaConfig] = useState(undefined);
  const [logoToRender, setLogoToRender] = useState<string | undefined>(undefined);
  const isPartnerOCA = _.some(partner) && !_.some(partnerLink);
  const isPartnerDealerOCA = _.some(partnerLink);

  useEffect(() => {
    if (!_.isEmpty(publicAccount)) {
      if (activeTemplate) {
        setOcaConfig(_.cloneDeep(activeTemplate.jsonDefinition));
      }

      const currentLocation = _.find(publicAccount.locations, { 'locationId': getURLParameter('locationId') });
      const logoToRender = _.get(currentLocation, "logo") || vendorLogo;
      setLogoToRender(logoToRender);
      try {
        //@ts-ignore
        window.waitForGlobal('_mfq', function () {
          //@ts-ignore
          if (publicAccount.name) {
            //@ts-ignore
            window._mfq.push(['setVariable', 'VENDOR', publicAccount.name]);
          }
          if (publicAccount.primaryContact.fullName) {
            //@ts-ignore
            window._mfq.push([
              'setVariable',
              'VENDOR_CONTACT',
              publicAccount.primaryContact.fullName,
            ]);
          }
        });
      } catch (e) { }
    }
  }, [publicAccount]);

  useEffect(() => {
    if (formId) {
      try {
        //@ts-ignore
        window.waitForGlobal('_mfq', function () {
          if (formId) {
            //@ts-ignore
            window._mfq.push(...window._mfq, [
              'setVariable',
              'FORM_ID',
              formId,
            ]);
          }
        });
      } catch (e) { }
    }
  }, [formId]);

  const getLenderDealerCoBrandedLayout = () => {
    return <Grid container className={classes.ldCoBrandedContainer}>
      <Grid item xs={12} className={classes.ldCoBrandedHeaderText}>
        <p>{coBrandingSettings.formWrapperHeaderText}</p>
      </Grid>
      <Grid item container xs={12} justifyContent={'center'}>
        <Grid item>
          {coBrandingSettings.formWrapperLogo &&
            <div className={`${styles.vendorLogoWrapper} ${classes.ldCoBrandedLogoWrapper}`}>
              <img
                src={
                  coBrandingSettings.formWrapperLogo.includes('/')
                    ? `https://${config.S3_BUCKET_NAME}.s3-us-west-2.amazonaws.com/${coBrandingSettings.formWrapperLogo}`
                    : `https://credit-app-images.s3-us-west-2.amazonaws.com/${coBrandingSettings.formWrapperLogo}`
                }
                alt="Vendor Logo"
              />
            </div>
          }
        </Grid>
        {coBrandingSettings.formWrapperContact &&
          <Grid
            item
            direction="column"
            justifyContent={'center'}
            className={classes.ldContactContainer}
          >
            <p><b>{coBrandingSettings.formWrapperContact.fullName}</b></p>
            <p>{coBrandingSettings.formWrapperContact.email}</p>
            <p>{coBrandingSettings.formWrapperContact.phoneNumber}</p>
          </Grid>
        }
      </Grid>
    </Grid>
  };

  if (!_.isEmpty(ocaConfig)) {
    return (
      <>
        <Grid container>
          {!isPartnerOCA && !isLenderDealerCoBranded && <Grid item xs={isPartnerDealerOCA ? 3 : 12}>
            {logoToRender ? (
              <div className={styles.vendorLogoWrapper}>
                <img
                  src={
                    logoToRender.includes('/')
                      ? `https://${config.S3_BUCKET_NAME}.s3-us-west-2.amazonaws.com/${logoToRender}`
                      : `https://credit-app-images.s3-us-west-2.amazonaws.com/${logoToRender}`
                  }
                  alt="Vendor Logo"
                />
              </div>
            ) : null}
          </Grid>
          }
          {(isPartnerOCA || isPartnerDealerOCA) &&
            <Grid item xs={isPartnerDealerOCA ? 9 : 12} className={classes.prequalifyContainer + (isPartnerOCA ? ` ${classes.prequalifyContainerPartner}` : '')} direction="column">
              <h5 style={{ color: vendorColor }} className={classes.prequalifyHeader}>
                <FormattedMessage id="prequalifyHeader" defaultMessage={defaultMessage.prequalifyHeader} />
              </h5>
              <p>
                <FormattedMessage id="completeInfoBelowAmount" defaultMessage={defaultMessage.completeInfoBelowAmount} />
              </p>
            </Grid>
          }
          {isLenderDealerCoBranded && coBrandingSettings && getLenderDealerCoBrandedLayout()}
        </Grid>

        <Container>
          <div className={classes.formContainer}>
            {/*             <hr
              className={styles.horizontalRule}
              style={{ borderColor: vendorColor, width: '100%' }}
            /> */}

            {!_.isEmpty(ocaConfig) ? (
              <OCAv4
                key="ocaV4"
                vendorContactId={vendorContactId}
                formId={formId}
                vendorGUID={vendorGUID}
                locationId={locationId}
                config={ocaConfig}
                setRepData={setRepData}
                publicData={publicAccount}
                setRepList={setRepList}
                activeTemplate={activeTemplate}
                extDmsOpportunityId={extDmsOpportunityId}
              />
            ) : null}

            <hr
              className={styles.horizontalRule}
              style={{ borderColor: vendorColor, width: '100%' }}
            />
            <Grid container spacing={4}>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={6}
                className={styles.sslLogoContainer}
              >
                <img src={sslLogo} alt="SSL Logo" className={styles.sslLogo} />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={6}
                className={styles.poweredByTrnsactLogoContainer}
              >
                <a href="http://dcr.ai/" target="_blank">
                  <img
                    src={LogoFull}
                    className={styles.poweredByTrnsactLogo}
                    alt="Powered By Trnsact"
                  />
                </a>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <p
                className={[
                  styles.footerText,
                  classes.pGrey,
                  classes.footerText,
                ].join(' ')}
              >
                <FormattedMessage
                  id="sslDisclaimer"
                  defaultMessage={defaultMessage.sslDisclaimer}
                />
              </p>
            </Grid>
            <p className={[classes.pGrey, classes.footerText].join(' ')}>
              <FormattedMessage
                id="fundingInquiryDisclaimer"
                defaultMessage={defaultMessage.fundingInquiryDisclaimer}
              />
            </p>
          </div>
        </Container>
      </>
    );
  }

  return null;
};

export default CreditApplicationFormWrapper;
