import { useFormikContext } from 'formik';
import _ from 'lodash';
import React, { useContext, useState } from 'react';
import { RuleEvaluatorWrapper } from './RuleEvaluationWrapper';
import { FieldType, RuleType } from './types';
import { ruleEvalTypeRun } from '../helpers';
import { useEffectDebugger } from '../helpers/customHooks';
import { FieldComponentFactory } from './FieldComponentFactory';
import { CommonDataContext } from '../context';

const Element: React.ElementType = ({
  item,
  locale,
  externalData,
  externalCallbacks,
}: {
  item: FieldType;
  locale: string;
  externalData?: any;
  externalCallbacks?: any;
}) => {
  const formik: any = useFormikContext();
  const { isMobileView } = useContext(CommonDataContext);
  const [isHiddenByAutofillRule, setIsHiddenByAutofillRule] = useState(false);

  const fieldName = _.get(item, 'config.fieldName');
  const fieldValue = _.get(formik, `values[${fieldName}]`);

  const autofillRules = _.filter(item.rules, (rule: RuleType) => rule.type === 'autofill');
  const autofillRulesTargetNames = _(autofillRules)
    .map('targetName')
    .uniq()
    .value();
  const dependenciesArray = _(autofillRulesTargetNames)
    .map(name => _.get(formik, `values[${name}]`))
    .value();

  useEffectDebugger((changedDeps: any) => {
    _.forEach(changedDeps, (result: any, targetName: any) => {
      const ruleToAutofill = _.find(autofillRules, (rule) => rule.targetName === targetName && ruleEvalTypeRun(rule, result.after))
      if (ruleToAutofill) {
        formik.setFieldValue(item.config.fieldName, ruleToAutofill.valueToAutofill);
        formik.setFieldError(item.config.fieldName, '');
        setIsHiddenByAutofillRule(!!ruleToAutofill.isNeedToHideOnAutofill);
      } else {
        //formik.setFieldValue(item.config.fieldName, '');  //?
        setIsHiddenByAutofillRule(false);
      }
    });
  }, dependenciesArray, autofillRulesTargetNames);

  if (isHiddenByAutofillRule) {
    return null;
  }

  try {
    return (
      <RuleEvaluatorWrapper
        rules={item.rules}
        elementType={'field'}
        rulesSatisfactionType={item.rulesSatisfactionType}
        children={FieldComponentFactory(
          item,
          _.get(formik, `values.${item.config.fieldName}`),
          locale,
          isMobileView,
          externalData,
          externalCallbacks,
        )}
      />
    );
  } catch (e) {
    if (e instanceof Error) {
      console.log(`Error Message ${e.message}`);
    }
  }
  return <></>;
};

export { Element };
